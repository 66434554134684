<template>
    <div>
        <ul class="flex flex-col mb-6 sm:flex-row px-3">
            <li v-for="(tab, index) in tabs" :key="tab.title"
                @click="selectTab(index)"
                class="text-gray-600 py-4 px-3 block cursor-pointer hover:text-smarta-blue-500 focus:outline-none"
                :class="{'text-smarta-blue-500 border-b-2 font-bold border-smarta-blue-500': (index == selectedIndex)}">
                {{ tab.title }}
            </li>
        </ul>
        <slot></slot>
    </div>
</template>

<style scoped>

</style>

<script>

export default {

    data () {
        return {
            selectedIndex: 0, // the index of the selected tab,
            tabs: [],        // all of the tabs
        }
    },
    created () {
        this.tabs = this.$children
    },
    mounted () {
        this.selectTab(0)
    },
    methods: {
        selectTab (i) {
            this.selectedIndex = i

            // loop over all the tabs
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i)
            })
        }
    }
}
</script>
