<template>
    <div>
        <!-- slot for parent component to activate the file changer -->
        <div @click="launchFilePicker()">
            <slot name="activator"></slot>
        </div>
        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input type="file"
               ref="file"
               :name="uploadFieldName"
               @change="uploadImage($event.target.name, $event.target.files)"
               style="display:none">
        <!-- error dialog displays any potential errors -->
        <div style="max-width: 240px;" v-if="errorDialog" class="w-48 mt-2 text-sm flex justify-center items-center bg-red-500 px-3 py-2 text-white" v-model="errorDialog">
            {{errorText}}
        </div>
        <div class="flex justify-center" v-else>
            <button type="button"  class="button pink flex items-center" v-if="removeImage" @click="deleteImage()">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                <span class="ml-2">remove</span>
            </button>
        </div>
    </div>
</template>


<script>
export default {
    /** Prop value will allows us to retrieve currently set and populated data **/
    props: ['value'],
    data: ()=> ({
        errorDialog: null,
        errorText: '',
        uploadFieldName: 'logo',
        maxSize: 1024,
        removeImage: false,
    }),

    methods: {
        launchFilePicker(){
            this.$refs.file.click();
        },
        uploadImage(fieldName, file) {
            const { maxSize } = this
            let imageFile = file[0]

            //check if user actually selected a file
            if (file.length>0) {
                let size = imageFile.size / maxSize / maxSize
                if (!imageFile.type.match('image.*')) {
                    // check whether the upload is an image
                    this.errorDialog = true
                    this.errorText = 'Please choose an image file'
                } else if (size>1) {
                    // check whether the size is greater than the size limit
                    this.errorDialog = true
                    this.errorText = 'Your file is too big! Please select an image under 1MB'
                } else {
                    this.errorDialog = false
                        let img = {
                            src: URL.createObjectURL(imageFile),
                            file: imageFile
                        }
                    this.removeImage = true
                    this.$emit('input', img)
                    flash('Image added', 'success');
                }
            }
        },
        deleteImage(){

            this.$buefy.dialog.confirm({
                title: 'Confirm delete',
                message: 'Are you sure you want to remove this image?',
                onConfirm: () => {
                    this.removeImage = false;
                    this.errorDialog = false
                    let imageFile = null;
                    let img = null;
                    this.$emit('removeImage', img);
                    flash('Image removed', 'danger');
                }
            })

        },
    }
}
</script>
