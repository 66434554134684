<template>
    <div v-if="items && items.length > 0" class="relative w-full">
        <swiper
            ref="testimonials"
            :options="swiperOptions"
        >
            <swiper-slide
                v-for="(item, index) in items"
                :key="index"
                class="swiper-slide"
            >
                <div class="flex w-full justify-center md:w-4/5 px-4 md:px-0 md:m-auto">
                    <div class="grid grid-cols-5 bg-gray-100 rounded-lg py-16 px-16">
                        <img src="/images/profile_image.svg" class="w-[100px] h-[100px] object-cover object-center" width="100px" height="100px">
                        <div class="col-span-4 ml-4 flex items-center">
                            <div>
                                <h3>{{ item.name }}</h3>
                                <h4>{{ item.position }}</h4>
                            </div>
                        </div>
                        <h2 class="pt-8 pb-4 col-span-5">{{ item.company }}</h2>
                        <div class="flex col-span-5">
                            <div class="mb-auto">
                                <img src="/images/quotes_open.svg" class="min-w-[60px] w-[60px] h-auto" width="60px" height="auto">
                            </div>
                            <div class="flex-grow text-center py-2 px-4 my-auto" v-html="item.quote" />
                            <div class="mt-auto">
                                <img src="/images/quotes_open.svg" class="min-w-[60px] w-[60px] h-auto" width="60px" height="auto">
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <nav class="contents">
            <button
              class="absolute top-0 left-0 flex items-center justify-start h-full pr-8 pl-2 m-0 z-40"
              @click="prevSlide"
            >
              <fa-icon :icon="['fas', 'circle-chevron-left']" size="2x" fixed-width class="text-smarta-pink-500" />
            </button>
            <button
              class="absolute top-0 right-0 flex items-center justify-end h-full pl-8 pr-2 m-0 z-40"
              @click="nextSlide"
            >
              <fa-icon :icon="['fas', 'circle-chevron-right']" size="2x" fixed-width class="text-smarta-pink-500" />
            </button>
        </nav>
    </div>
</template>
<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

    export default {
        name: 'TestimonialsSlider',

        components: {
            Swiper,
            SwiperSlide
        },

        props: {
            value: {
                type: Array,
                required: false,
                default: () => { return [] }
            },
        },

        data () {
            return {
                items: this.value,
                swiperOptions: {
                    loop: true,
                    autoplay: false,
                    spaceBetween: 30,
                    navigation: {
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next'
                    }
                }
            }
        },

        computed: {
            swiper () {
                return this.$refs.testimonials.$swiper
            }
        },

        methods: {
            prevSlide () {
                this.swiper.slidePrev()
            },

            nextSlide () {
                this.swiper.slideNext()
            }
        }
    }
</script>
