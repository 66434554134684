<template>
	<article class="shadow rounded-3xl overflow-hidden bg-white h-full">
		<div class="flex flex-col h-full">
			<div class="aspect-w-2 aspect-h-1">
				<a :href="entry.permalink" class="block rounded-b-3xl overflow-hidden shadow-lg bg-white">
					<img
						v-if="entry.wide_image_preview"
						:src="getGlideImage(entry.wide_image_preview, 644, 322)"
						:alt="entry.title || null"
						class="absolute top-0 left-0 w-full h-full object-cover"
						loading="lazy"
						width="322"
						height="161"
					>
					<img
						v-else-if="entry.image_preview"
						:src="getGlideImage(entry.image_preview, 322, 322)"
						:alt="entry.title || null"
						class="absolute top-0 left-0 w-full h-full object-cover"
						loading="lazy"
						width="322"
						height="322"
					>
				</a>
			</div>
			<div class="flex flex-col flex-grow text-gray-900 h-full px-6 py-6">
				<a :href="entry.permalink" class="w-full mb-2 2xl:mb-4">
					<h2 class="text-2xl 2xl:text-3xl line-clamp-2">{{ entry.title || null }}</h2>
				</a>
				<div class="flex-grow text-gray-500 mb-2 2xl:mb-4">
					<p class="line-clamp-2">{{ entry.blurb || null }}</p>
				</div>
				<span v-if="entry.difficulty_level" class="font-bold text-lg mb-4">
					{{ entry.difficulty_level.label }}
				</span>
				<div v-if="tags && tags.entries && tags.entries.length > 0" class="tags flex mb-4">
					<div class="mr-2">
						<fa-icon :icon="['fas', 'tag']" size="lg" fixed-width />
					</div>
					<div class="flex-grow flex flex-wrap text-sm 2xl:text-base">
						<template v-if="tagFiltersEnabled">
							<span v-for="tag in tags.entries" :key="tag.id" tabindex="0" class="underline cursor-pointer mr-4" @click="$emit('add-filter', 'blog_tags', tag.slug)">{{ tag.title }}</span>
						</template>
						<template v-else>
							<span v-for="tag in tags.entries" :key="tag.id" class="mr-4">{{ tag.title }}</span>
						</template>
						<span v-if="tags.count > tags.entries.length">+{{ tags.count - tags.entries.length }} more</span>
					</div>
				</div>
				<div>
					<a :href="entry.permalink" class="button button__pink text-center w-full 2xl:w-auto px-10 py-4">
						Discover More
					</a>
				</div>
			</div>
		</div>
	</article>
</template>
<script>
	import ShowsGlideImage from '../../mixins/ShowsGlideImage'

	export default {
		name: 'CourseListSingle',

		mixins: [
			ShowsGlideImage
		],

		props: {
			entry: {
				type: Object,
				required: true
			},

			tagFiltersEnabled: {
				type: Boolean,
				required: false,
				default: () => { return false }
			}
		},

		computed: {
			tags () {
				const length = 2
				return {
					limit: length,
					count: this.entry?.blog_tags?.length || 0,
					entries: this.entry?.blog_tags?.slice(0, length) || []
				}
			}
		},

		methods: {
			syncToUserFavourites () {
				const course = this.entry.id

				if (this.entry.favourite) {
					// axios to remove from user meta
				} else {
					// axios to add to user meta
				}
			}
		}
	}
</script>
