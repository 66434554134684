const state = {
	enabled: false,
	show: false,
	postcode: null,
	course: null,
}

const getters = {
	enabled: (state) => {
		return state.enabled
	},

	visible: (state) => {
		return state.show
	},

	postcode: (state) => {
		return state.postcode
	},

	postcodeSupplied: (state) => {
		return state.postcode !== null
	},

	course: (state) => {
		return state.course
	}
}

const mutations = {
	open (state) {
        state.show = true
    },

    close (state) {
        state.show = false
    },

	setPostcode (state, postcode) {
		state.postcode = postcode
	},

	setCourse (state, course) {
		state.course = course
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}
