<template>
    <div class="w-full md:w-1/2 lg:w-1/3 flex flex-col">
        <div class="card listing flex flex-col bg-white flex-grow my-4 px-4 py-4 mx-4">
            <div class="aspect-w-1 aspect-h-1">
                <a :href="listingHref">
                    <img class="object-contain object-center" :src="imageSrc">
                </a>
            </div>
            <h3 class="mx-4 text-smarta-green-500 mt-4 mb-2"><a :href="listingHref" class="hover:text-smarta-green-700">{{ listing.title }}</a></h3>
            <p class="mx-4 flex-grow">{{ listing.business_intro_description }}</p>
            <div class="flex flex-col mx-4">
                <div v-if="listing.listing_locations && listing.listing_locations.length > 0" class="flex items-start mb-4">
                    <div class="flex">
                        <svg class="w-6 h-6 text-smarta-purple-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0002.783 4.118z" clip-rule="evenodd"></path></svg>
                    </div>
                    <div class="flex flex-wrap">
                        <span
                            class="ml-2 text-smarta-green-500 capitalize "
                            v-for="(location, key) in listing.listing_locations"
                            :key="key"
                        >
                            {{ location.city }}
                        </span>
                    </div>
                </div>
                <div v-if="listing.sectors && listing.sectors.length > 0" class="flex items-start mb-4">
                    <div class="flex">
                        <svg class="w-6 h-6 text-smarta-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z"></path></svg>
                    </div>
                    <div class="flex flex-wrap">
                        <span
                            class="ml-2 text-smarta-pink-500"
                            v-for="(sector, key) in listing.sectors"
                            :key="key"
                        >
                            {{ sector.name }}
                        </span>
                    </div>
                </div>
                <div v-if="audience(listing.company_type)" class="flex items-start mb-4">
                    <div class="flex">
                        <svg class="w-6 h-6 text-smarta-pink-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                    </div>
                    <div class="flex flex-wrap" >
                        <span class="ml-2 text-smarta-pink-500">
                            {{ audience(listing.company_type) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        listing: {
            required: true,
            type: Object,
            required: true
        }
    },
    methods: {
        audience(company_type) {
            switch (company_type) {
                case "d_2_c":
                    return "Direct to consumer";
                case "b_2_b":
                    return "Business to business";
                default:
                    return null;
            }
        }
    },
    computed: {
        imageSrc() {
            return this.listing.company_logo_path || "/images/holding_image.svg";
        },
        listingHref() {
            return "/trade/" + this.listing.slug;
        }
    }
}
</script>

<style scoped>

</style>
