<template>
    <div class="flex flex-col w-full">
        <form @submit.prevent="submit">
            <div class="px-2">
                <div class="horizontal flex flex-col my-4">
                    <label for="email" class="form_field_label">Email</label>
                    <input
                        id="email"
                        type="email"
                        class="w-full form_field_input focus:outline-none"
                        name="current_password"
                        v-model="email"
                        :class="errors.email ? 'border-red-600' : 'border-muted-light'"
                        required
                    >
                </div>
            </div>
            <div class="mb-4 px-2">
                <div v-if="errors_exist">
                    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <span class="validation w-full text-sm text-red-500" v-if="errors.email" v-text="errors.email"></span>
                    </div>
                </div>
            </div>
            <footer class="flex items-centre justify-end mt-12">
                <button
                    type="submit"
                    class=" flex justify-center pink button"
                    :disabled="isDisabled">
                    {{ isCreatingProfile ? "Updating..." : "Update email" }}
                </button>
            </footer>
        </form>
    </div>
</template>
<script>
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            email: this.user.email,
            isCreatingProfile: false,
            isDisabled: false,
            errors: [],
            errors_exist: false,
        };
    },

    methods: {
        submit() {
            this.isCreatingProfile = true;
            this.isDisabled = true;

            axios.patch(`/account/${this.user.id}`, {
                email: this.email
            })
                .then((res) => {
                    this.submitted = true
                    this.errors = []
                    window.location = response.data.message
                })
                .catch((err) => {
                    this.errors_exist = true
                    this.errors = err.response.data.errors || []
                    this.submitted = false
                    this.isCreatingProfile = false
                    this.isDisabled = false
                    flash('Oops, something went wrong. Please try again.', 'danger');
                })
        }
    }
}
</script>
