<template>
    <div>
        <div>
            <b-switch
                v-model="publishOffer"
                true-value="1"
                false-value="0"
                @input="approveConfirm(product)"
            />
        </div>
    </div>

</template>

<script>

export default {
    props: ['product', 'offer_active'],

    data(){
        return {
            publishOffer: this.offer_active,
        }
    },
    /** Methods block allows us to perform various actions **/
    methods: {
        /** Modal for confirm trigger via @click event and then if confirmed trigger the delete function **/
        approveConfirm() {


            this.$buefy.dialog.confirm({
                title: 'Confirm approval',
                message: 'Once approved, listing will be live',
                onConfirm: () => this.approveListing()
            })

            console.log(this.publishOffer)
        },
        approveListing(listing) {
            let formData = new FormData();

            //patch request as needed to multipart form

            formData.append('_method', 'PATCH');

            if (this.publishOffer === 0 || this.publishOffer === '0'){
                formData.append('is_published', 0);
            }else{
                formData.append('is_published', 1);
            }


            api.post('products/'+ this.product, formData)
                .catch(
                    this.onFail.bind(this),
                )
                .then(
                    response => {
                        this.errors = {};
                        flash('Offer live', 'success');
                    }
                );
        },
        /*** Here we Look for errors and return to the catch block  ***/
        onFail(error){
            flash('Opps, something went wrong', 'danger');
            this.errors = error.response.data.errors;
            throw error;

        }

    }
};
</script>
