<template>
    <b-modal
        v-model="isModalVisible"
        :width="640"
        scroll="keep"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        :on-cancel="closeModal"
        aria-role="dialog"
        aria-label="Modal"
        aria-modal>
        <div class="modal-card">
            <div class="modal-card-head">
                <slot name="header">
                    <p>Header</p>
                </slot>
            </div>
            <div class="modal-card-body">
                <slot name="body">
                    <p>Body</p>
                </slot>
            </div>
            <div class="modal-card-foot">
                <slot name="footer">
                    <button class="button green" @click="closeModal">OK</button>
                </slot>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'modal',
    props: ['value'],
    data() {
        return {
            isModalVisible: this.value,
        }
    },
    watch: {
        value: function(newVal, oldVal) { // watch it
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            this.isModalVisible = this.value
        }
    },
    methods: {
        closeModal(){
            this.$emit('close', this.isModalVisible);
        }
    },
};
</script>
