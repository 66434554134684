<template>
    <div class="flex flex-col w-full">
        <form @submit.prevent="submit">
            <div class="relative w-full md:w-full px-3 mb-6">
                <div class="horizontal flex flex-col my-4">
                    <label for="userFirstName" class="form_field_label">First Name<required /></label>
                    <input
                        type="text"
                        id="userFirstName"
                        class="w-full form_field_input focus:outline-none"
                        placeholder="John"
                        v-model="userFirstName"
                        required
                    >
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.first_name"
                        v-text="errors.first_name[0]">
                    </span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label for="userLastName" class="form_field_label">Surname<required /></label>
                    <input
                        type="text"
                        id="userLastName"
                        class="w-full form_field_input"
                        placeholder="Smith"
                        v-model="userLastName"
                        required
                    >
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.last_name"
                        v-text="errors.last_name[0]">
                    </span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label for="userCompany" class="form_field_label">Company Name</label>
                    <input
                        type="text"
                        id="userCompany"
                        class="w-full form_field_input"
                        placeholder="Company Name"
                        v-model="userCompany"
                    >
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.user_company"
                        v-text="errors.user_company[0]">
                    </span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label for="userCompanyAddress" class="form_field_label">Company Address</label>
                    <textarea
                        id="userCompanyAddress"
                        class="w-full form_field_input"
                        placeholder="Company Address"
                        v-model="userCompanyAddress"
                        rows="5"
                    ></textarea>
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.user_company_address"
                        v-text="errors.user_company_address[0]">
                    </span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label for="userCompanyPostcode" class="form_field_label">Company Postcode</label>
                    <input
                        type="text"
                        id="userCompanyPostcode"
                        class="w-full form_field_input"
                        placeholder="Company Postcode"
                        maxlength="10"
                        v-model="userCompanyPostcode"
                    >
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.user_company_postcode"
                        v-text="errors.user_company_postcode[0]">
                    </span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label for="userProfession" class="form_field_label">Job Title</label>
                    <input
                        type="text"
                        id="userProfession"
                        class="w-full form_field_input"
                        placeholder="Job Title"
                        v-model="userProfession"
                    >
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.user_profession"
                        v-text="errors.user_profession[0]">
                    </span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label for="userTelephone" class="form_field_label">Contact number<required /></label>
                    <input
                        type="text"
                        id="userTelephone"
                        class="w-full form_field_input"
                        placeholder="Phone number"
                        v-model="userTelephone"
                        required
                    >
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.user_telephone"
                        v-text="errors.user_telephone[0]">
                    </span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label for="userAddress" class="form_field_label">Home Address<required/></label>
                    <textarea
                        id="userAddress"
                        class="w-full form_field_input"
                        placeholder="Home Address"
                        v-model="userAddress"
                        :readonly="!addressIsEmpty"
                        rows="5"
                        required
                    ></textarea>
                    <small
                        v-if="!addressIsEmpty"
                        class="mt-1"
                    >
                        Please contact us if you wish to change this.
                    </small>
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.user_address"
                        v-text="errors.user_address[0]">
                    </span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label for="userPostcode" class="form_field_label">Home Postcode<required/></label>
                    <input
                        type="text"
                        id="userPostcode"
                        class="w-full form_field_input"
                        placeholder="Home Postcode"
                        v-model="userPostcode"
                        :readonly="!postcodeIsEmpty"
                        required
                    >
                    <small
                        v-if="!postcodeIsEmpty"
                        class="mt-1"
                    >
                        Please contact us if you wish to change this.
                    </small>
                    <span
                        class="validation w-full text-sm text-red-500 mt-1"
                        v-if="errors.user_postcode"
                        v-text="errors.user_postcode[0]">
                    </span>
                </div>
            </div>

            <!-- Error Bag -->
            <div v-if="errorsExist">
                <div class="relative mx-3 py-2 mb-4">
                    <button class="absolute top-0 right-0" type="button" @click="closeValdiationBlock">
                        <svg class="w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </button>
                    <div v-for="(v, k) in errors" :key="k">
                        <div class="bg-white flex items-center mb-3">
                            <div class="border-l-4 p-4 border-red-500">
                                <svg class="text-red-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            </div>
                            <p v-for="error in v" :key="error" class="text-gray-500 mb-0">
                                {{ error }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- CTAs -->
            <div class="flex items-centre justify-end mt-12">
                <button
                    type="button"
                    class="flex justify-center pink button"
                    :disabled="isDisabled"
                    @click="confirmSubmission"
                >
                    {{ isCreatingListing ? "Updating..." : "SAVE" }}
                </button>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            userId: this.user.id,
            userFirstName: this.user.first_name || null,
            userLastName: this.user.last_name || null,
            userTelephone: this.user.user_telephone || null,
            userCompany: this.user.user_company || null,
            userCompanyAddress: this.user.user_company_address || null,
            userCompanyPostcode: this.user.user_company_postcode || null,
            userProfession: this.user.user_profession || null,

            userAddress: this.user.user_address || null,
            userAddressOriginal: this.user.user_address || null,

            userPostcode: this.user.user_postcode || null,
            userPostcodeOriginal: this.user.user_postcode || null,

            errors: [],
            errorsExist: false,
            canSubmit: null,

            isLoading: false,
            isFullPage: true,
            isCreatingListing: false,
            isDisabled: false,
        }
    },

    computed: {
        addressIsEmpty () {
            return this.userAddressOriginal === null || this.userAddressOriginal === ''
        },

        postcodeIsEmpty () {
            return this.userPostcodeOriginal === null || this.userPostcodeOriginal === ''
        }
    },

    methods: {
        closeValdiationBlock () {
            this.errorsExist = false;
            this.errors = [];
        },

        confirmSubmission () {
            this.errorsExist = false;
            this.errors = [];

            this.$buefy.dialog.confirm({
                title: 'Confirm Submission',
                message: 'Save changes made to profile?',
                onConfirm: () => this.submit()
            })
        },

        submit () {
            this.isDisabled = true;
            this.isLoading = true;

            axios.patch(`/profile/${this.userId}`, {
                id: this.userId || null,
                first_name: this.userFirstName || null,
                last_name: this.userLastName || null,
                user_telephone: this.userTelephone || null,
                user_company: this.userCompany || null,
                user_company_address: this.userCompanyAddress || null,
                user_company_postcode: this.userCompanyPostcode || null,
                user_profession: this.userProfession || null,
                user_address: this.userAddress || null,
                user_postcode: this.userPostcode || null
            })
                .then((res) => {
                    this.submitted = true;
                    this.errors = {};
                    flash('Your profile has been updated successfully!', 'success');
                    window.location = res.data.message;
                })
                .catch((err) => {
                    this.errorsExist = true
                    this.errors = err.response.data.errors || [];
                    this.submitted = false;
                    this.isCreatingListing = false;
                    this.isLoading = false;
                    this.isDisabled = false;
                    flash('Oops, something went wrong. Please try submitting again.', 'danger');
                })
        }
    },
}
</script>
