<template>
    <b-modal
        v-model="visible"
        id="smarta-landing-modal"
        :destroy-on-hide="true"
        :on-cancel="close"
        scroll="keep"
        aria-role="dialog"
        aria-label="Modal"
        has-modal-card
        trap-focus
        aria-modal
    >
        <div class="fixed inset-0 flex justify-center h-screen w-screen max-w-[100vw] w-full overflow-x-hidden">
            <div aria-hidden="true" class="absolute inset-0 bg-white opacity-90"></div>
            <div class="relative w-full h-full flex items-center justify-center">
                <div class="landing-inner relative flex flex-wrap md:justify-end overflow-y-auto max-h-screen z-10 pt-10 pb-[120px] md:pb-10">
                    <button
                        type="button"
                        aria-label="Close modal"
                        class="w-[39px] h-[39px] mb-12 md:mb-0 md:w-24 md:h-24 relative md:absolute top-1/2 md:-translate-y-full left-0 z-20 transition duration-300 hover:rotate-90 cursor-pointer"
                        @click="close"
                    >
                        <span class="sr-only">Close</span>
                        <div aria-hidden="true" class="w-1.5 h-12 bg-smarta-b2b-pink absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rotate-45"></div>
                        <div aria-hidden="true" class="w-1.5 h-12 bg-smarta-b2b-pink absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 -rotate-45"></div>
                    </button>
                    <div class="w-full md:w-1/2 xl:w-5/12 drop-shadow-md">
                        <slot name="form"></slot>
                    </div>
                </div>
                <div
                    aria-hidden="true"
                    class="absolute top-0 left-0 w-full h-full bg-cover select-none hidden lg:inline-block"
                    style="background-image: url('/images/smarta-lava-bg-desktop.png')"
                />
                <div
                    aria-hidden="true"
                    class="absolute top-0 left-0 w-full h-full bg-cover select-none hidden md:inline-block lg:hidden"
                    src="/images/smarta-lava-bg-tablet.png"
                />
                <div
                    aria-hidden="true"
                    class="absolute top-0 left-0 w-full h-full bg-cover select-none md:hidden"
                    style="background-image: url('/images/smarta-lava-bg-mobile.png')"
                />
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    name: 'LandingFormModal',

    props: ['value'],

    data () {
        return {
            visible: this.value,
        }
    },

    watch: {
        value: function() {
            this.visible = this.value
        }
    },

    methods: {
        close () {
            this.$emit('close', this.visible);
        }
    }
}
</script>
<style>
    #smarta-landing-modal {
        max-width: 100vw;
        overflow-x: hidden;
    }
    #smarta-landing-modal .modal-close {
        display: none;
    }
</style>
