const state = {
    show: false,
    item: null,
    confirmUrl: null
}

const getters = {
    visible: (state) => {
        return state.show
    },

    course: (state) => {
        return state.item
    },

    confirmUrl: (state) => {
        return state.confirmUrl
    }
}

const mutations = {
    open (state) {
        state.show = true
    },

    close (state) {
        state.show = false
    },

    setCourse (state, item) {
        state.item = item
    },

    clearCourse (state) {
        setTimeout(() => {
            state.item = null
        }, 500)
    },

    setConfirmUrl (state, url) {
        state.confirmUrl = url
    },

    clearConfirmUrl (state) {
        setTimeout(() => {
            state.confirmUrl = null
        }, 500)
    }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
