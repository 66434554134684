<template>
	<div>
		<a
			v-if="entry.trustpilot && entry.trustpilot.rating !== null"
			:href="entry.trustpilot_url"
			target="_blank"
			rel="noopener"
			class="flex flex-col items-start"
		>
			<img :src="entry.trustpilot.star_image" class="max-h-[30px]" :aria-label="`Rated ${entry.trustpilot.rating || '0.0'} from ${entry.trustpilot.review_count} reviews on TrustPilot`">
			<small>TrustScore <strong>{{ entry.trustpilot.rating || 'N/A' }}</strong> | {{ entry.trustpilot.review_count || '0' }} reviews</small>
		</a>
		<div v-else-if="showMissing">No TrustScore found for this item.</div>
	</div>
</template>
<script>
	export default {
		name: 'TrustPilot',

		props: {
			entry: {
				type: Object,
				required: true
			},

			showMissing: {
				type: Boolean,
				required: false,
				default: () => { return true }
			}
		}
	}
</script>
