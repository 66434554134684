<template>
    <div>
        <input :id="name" type="hidden" :name="name" :value="value">
        <trix-editor ref="trix" :input="name" :placeholder="placeholder"></trix-editor>
    </div>
</template>
<style scoped>
/*** Trix editor overrides ***/

trix-editor {
    border-radius: 0!important;
    border: 1px solid!important;
    border-color: rgba(11,16,19,0.1)!important;
    min-height: 15rem!important;
    background:#ffffff;

}

.trix_description trix-editor{
    border-radius: 0!important;
    border: 1px solid!important;
    border-color: rgba(11,16,19,0.1)!important;
    min-height: 15rem!important;
    background:#ffffff;
}

trix-editor ul {
    list-style: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

</style>
<script>
import Trix from 'trix';

export default {

    props: ['name', 'value', 'placeholder'],

    //https://www.npmjs.com/package/trix
    //https://allankiezel.com/how-to-reuse-trix-wysiwyg-editor-with-vue-js/
    watch: {
        value (value) {
            value = value === undefined ? '' : value;
            if (this.$refs.trix.innerHTML !== value) {
                this.$refs.trix.editor.loadHTML(value);
            }
        }
    },

    mounted () {
        //Emit data to parent
        this.$refs.trix.addEventListener('trix-change', e => {
            this.$emit('input', e.target.innerHTML);
        });

        //Set init state of content if value exists
        if (this.$refs.trix.innerHTML !== this.value) {
            this.$refs.trix.editor.loadHTML(this.value);
        };

    }

}
</script>

