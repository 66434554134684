<template>
    <div class="flex flex-row flex-wrap w-full items-start restrain mx-auto">
        <div class="flex flex-col md:pr-4  my-8 w-full md:w-1/3 lg:w-1/4">
            <filters-component @updateFilters="updateFilters" :categories="categories" :deals="deals" :filter-category-counts="filterCategoryCounts" :filter-deal-counts="filterDealCounts"></filters-component>
        </div>
        <product-grid-component :products="products"></product-grid-component>
        <div class="flex justify-end" style="width: 100%">
            <div v-for="pageIndex in pages" @click="changePage(pageIndex)" :class="['mx-1', 'underline', page == pageIndex ? 'text-smarta-pink-700' : 'text-smarta-pink-500', 'hover:text-smarta-pink-700']" style="cursor:pointer">{{ pageIndex }}</div>
        </div>
        <div class="flex justify-end mt-4" style="width: 100%">
            <select class="w-24" @change="loadProducts" v-model="items">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchQuery: "",
            selectedFilters: [],
            products: [],
            pages: 1
        }
    },
    props: {
        categories: {
            required: true,
            type: Array
        },
        deals: {
            required: true,
            type: Array
        },
        previousQuery: {
            required: false,
            type: String,
            default: null
        },
        previousFilters: {
            required: false,
            default: []
        },
        filterCategoryCounts: {
            required: true,
            type: Array
        },
        filterDealCounts: {
            required: true,
            type: Array
        },
        page: {
            required: false,
            default: 1
        },
        items: {
            required: false,
            default: 15
        }
    },
    created() {
        if (typeof this.previousFilters == "object") {
            // If previous filters are set then set the data stores we use for filters
            this.selectedFilters = this.previousFilters;

            // Set active category filters as selected
            if (typeof this.previousFilters.categories != "undefined") {
                this.previousFilters.categories.forEach((item) => {
                    this.categories.forEach((category, categoryIndex) => {
                        if (category.slug == item.slug) {
                            this.categories[categoryIndex]['selected'] = item.selected;
                            return;
                        }
                    });
                });
            }

            // Set active deals filters as selected
            if (typeof this.previousFilters.deals != "undefined") {
                this.previousFilters.deals.forEach((item) => {
                    this.deals.forEach((deal, dealIndex) => {
                        if (deal.slug == item.slug) {
                            this.deals[dealIndex]['selected'] = item.selected;
                            return;
                        }
                    });
                });
            }
        }
    },
    mounted() {
        // Listen for filter and search update events
        this.$root.$on('updateFilters', this.updateFilters);
        this.$root.$on('updateSearch', this.updateSearch);

        if (this.previousQuery != null) {
            this.searchQuery = this.previousQuery;
        }

        this.loadProducts();
    },
    methods: {
        changePage(pageIndex) {
            this.page = pageIndex;
            this.loadProducts();
        },
        loadProducts() {
            let productSearchQuery = this.generateQuery();
            this.fetchProducts(productSearchQuery);
        },
        updateSearch(query) {
            this.searchQuery = query;

            this.loadProducts();
        },
        updateFilters(data) {
            // Only return data on selected filters and then filter out any null filters so we
            //  are left only with the selected data
            this.selectedFilters[data.slug] = data.items.map((item) => {
                if (item.selected) {
                    return item;
                }

                return null;
            }).filter((item) => {
                return item != null;
            });

            this.loadProducts();
        },
        /**
         * Build a query string from search query and filters
         *
         * Take the search string and the filter arrays and convert them into a valid query string
         *  to be used as a GET parameter. Filters are passed as multi-dimen arrays under $_GET['filter'].
         *  Each filter has its slug passed through as an array item, for example:
         *  $_GET['filter'] = [
         *    'categories' => [
         *      'e-commerce',
         *      'hr',
         *      'software'
         *    ],
         *    'deals' => [
         *      'free-products',
         *      'free-trials'
         *    ]
         *  ];
         *
         */
        generateQuery() {
            let queryString = "?";

            // If there is a searchQuery set then add it to the query string first
            if (this.searchQuery != "") {
                queryString += "search=" + this.searchQuery;
            }

            // If filters are selected then we have to convert the array into a string
            if (Object.keys(this.selectedFilters).length > 0) {
                var filterQuery = "";
                // Grab the filter groups from the selected filters
                let filters = Object.keys(this.selectedFilters);
                // Loop through the filter groups and build the correct string for each group
                for (let i = 0; i < filters.length; i++) {
                    // This will generate something like `&filter[categories][]=e-commerce`
                    this.selectedFilters[filters[i]].forEach((item) => {
                        filterQuery += "&filter[" + filters[i] + "][]=" + item.slug;
                    });
                }

                if (this.searchQuery == "") {
                    // If there is no searchQuery then remove the `&` from the beginning of the
                    //  filterQuery so we get `?filter=...`
                    queryString += filterQuery.substring(1);
                } else {
                    // Otherwise just append the filterQuery to the searchQuery
                    queryString += filterQuery;
                }
            }

            // If the query string is just `?` then there is no search or filter
            if (queryString == "?" && this.page == 1 && this.items == 25) {
                return "";
            }

            if (queryString == "?") {
                queryString += "page=" + this.page + "&items=" + this.items;
            } else {
                queryString += "&page=" + this.page + "&items=" + this.items;
            }

            return queryString;
        },
        fetchProducts(query) {
            api.get("/api/products/search" + query).then((resp) => {
                this.products = resp.data.products;
                this.pages = resp.data.pages;

                history.pushState({}, "Product Search", "/shop" + query);
            }).catch((resp) => {
                // TODO: Handle this properly
                console.log("catch");
                console.log(resp);
            });
        }
    }
}
</script>

<style scoped>

</style>
