<template>
    <div v-if="items && items.length > 0" class="relative w-full">
        <swiper
            ref="collection"
            :options="swiperOptions"
        >
            <swiper-slide
                v-for="(item, index) in items"
                :key="index"
                class="swiper-slide"
            >
                <component
                    v-if="item"
                    :is="collectionComponent"
                    :entry="item"
                />
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import CourseEntry from './courses/SingleList'
    import ProductEntry from './products/SingleDomino'
    import ListingEntry from './listings/SingleDomino'
    import AdviceEntry from './advice/Item'

    export default {
        name: 'CollectionsSlider',

        components: {
            Swiper,
            SwiperSlide
        },

        props: {
            type: {
                type: String,
                required: true
            },

            items: {
                type: Array,
                required: false,
                default: () => { return [] }
            },
        },

        data () {
            return {
                swiperOptions: {
                    loop: true,
                    autoplay: false,
                    spaceBetween: 30
                }
            }
        },

        computed: {
            swiper () {
                return this.$refs.collection.$swiper
            },

            collectionComponent () {
                switch (this.type) {
                    case 'knowledge_courses':
                        return CourseEntry
                        break;
                    case 'products':
                        return ProductEntry
                        break;
                    case 'listings':
                        return ListingEntry
                        break;
                    case 'advice':
                        return AdviceEntry
                        break;
                }
            }
        },

        methods: {
            prevSlide () {
                this.swiper.slidePrev()
            },

            nextSlide () {
                this.swiper.slideNext()
            }
        }
    }
</script>
