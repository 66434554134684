import Vue from 'vue'
import Vuex from 'vuex'
import videoModal from './modules/video-modal'
import fundingModal from './modules/funding-modal'
import microcourseModal from './modules/microcourse-modal'
import compare from './modules/compare'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    videoModal,
    fundingModal,
    microcourseModal,
    compare
  }
})
