<template>
    <div id="listing_pricing">
        <div class="flex flex-col md:flex-row md:flex-wrap">


                <div class="relative md:flex w-full px-3 md:px-0 md:mx-3 pb-6 border-b border-smarta-pink-500 mb-6" v-for="(block, key) in blocks"  v-bind:key="key">

                    <div class="absolute top-0 right-0 mr-3">
                        <b-tooltip label="Remove block" :delay="250">
                            <span class="cursor-pointer" @click="deleteBlock(key)" title="Remove block">
                            <svg class="w-5 h-5 text-smarta-pink-500 hover:text-smarta-pink-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </span>
                        </b-tooltip>
                    </div>

                    <div class="relative md:w-1/2 mt-6 md:pr-3" >
                        <div class="horizontal flex flex-col my-4">
                            <label class="form_field_label" for="product_title">{{key+1}}. Product</label>
                            <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Product name" id="product_title" v-model="blocks[key].product_title" >
                            <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.blocks[key].product_title.is_valid">{{ validations.blocks[key].product_title.text }}</span>
                        </div>
                        <div class="horizontal flex flex-col mb-4">
                            <label class="form_field_label" for="offer_type">Offer type:</label>
                            <input class="w-full form_field_input focus:outline-none" type="text" placeholder="One time purchase" id="offer_type" v-model="blocks[key].offer_type">
                            <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.blocks[key].offer_type.is_valid">{{ validations.blocks[key].offer_type.text }}</span>
                        </div>
                        <div class="horizontal flex justify-between mb-4" >
                            <div class="w-1/2 mr-2">
                                <div class="">
                                    <!-- Price input block -->
                                    <label for="unit_price" class="form_field_label">Unit price</label>
                                    <input
                                        type="number"
                                        id="unit_price"
                                        min="1"
                                        step="any"
                                        class="w-full form_field_input focus:outline-none"
                                        placeholder="99.99"
                                        v-model="blocks[key].unit_price"
                                    >
                                    <!-- End block -->
                                </div>
                            </div>
                            <div class="w-1/2 ml-2" >
                                <div class="">
                                    <!-- Offer input block -->
                                    <label for="offer_price" class="form_field_label">Offer price</label>
                                    <input
                                        type="number"
                                        id="offer_price"
                                        min="1"
                                        step="any"
                                        class="w-full form_field_input focus:outline-none"
                                        placeholder="69.99"
                                        v-model="blocks[key].offer_price"
                                    >
                                    <!-- End block -->
                                </div>
                            </div>
                        </div>
                        <div class="horizontal flex flex-col mb-4">
                            <label class="form_field_label" for="offer_type">Link</label>
                            <input class="w-full form_field_input focus:outline-none" type="text" placeholder="https://product.com" id="cta_link" v-model="blocks[key].cta_link">
                        </div>
                        <div class="horizontal flex flex-col mb-4">
                            <label class="form_field_label" for="offer_type">Link text</label>
                            <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Buy now" id="cta_link_text" v-model="blocks[key].cta_link_text">
                        </div>
                    </div>

                    <div class="relative md:w-1/2 mt-6 md:pl-3" >
                        <div class="horizontal flex flex-col my-4">
                            <!-- Intro input block -->
                            <label for="offer_description" class="form_field_label">Intro</label>
                            <textarea v-model="blocks[key].offer_description" class="font-body form_field_input focus:outline-none" placeholder="A short description of offer"></textarea>
                            <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.blocks[key].offer_description.is_valid">{{ validations.blocks[key].offer_description.text }}</span>
                            <!-- End block -->
                        </div>
                        <div class="horizontal flex flex-col items-end mb-4">
                            <!-- Feature block -->
                            <label class="form_field_label" for="feature_row">Features</label>
                            <div class="relative w-full px-3" v-for="(feature, ind) in blocks[key].feature_list" v-bind:key="ind">
                                <div class="horizontal flex flex-col my-2">

                                    <div class="flex items-center">
                                        <div class="flex items-center flex-1">
                                            <label class="flex mr-2" for="feature_row">{{ind+1}}:</label>
                                            <div  class="flex-1">
                                                <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Feature..." id="name" v-model="feature.name" >
                                            </div>
                                        </div>
                                        <div class="ml-3 relative">
                                            <b-tooltip label="Remove block" :delay="250">
                                                <span class="cursor-pointer" @click="deleteFeature(key, ind)" title="Remove block">
                                                <svg class="w-5 h-5 text-smarta-pink-500 hover:text-smarta-pink-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                                </span>
                                            </b-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End block -->
                            <input type="text" placeholder="Feature name" class="w-full form_field_input focus:outline-none" v-model="blocks[key].newFeature">
                            <button type="button"  class="button  green items-start mt-4" @click="addFeature(key)">Add</button>
                        </div>
                    </div>

                </div>

        </div>

        <div class="button_group flex flex-row px-3 mb-6">
            <!-- We have set the default button states in the data below, if true show, if false hide -->
            <button type="button"  class="button green" v-if="addBlockButton" @click="addBlock">Add pricing block</button>
            <button type="button"  class="button pink" v-if="finishBlockButton" @click="saveNewBlock">Save pricing block</button>
        </div>
    </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<script>
export default {
    /** Prop value will allows us to retrieve currently set and populated data **/
    props: ['value'],
    data() {
        return {
            blocks:this.value,
            validations: {
                blocks: [
                ],
            },
            blockActive: false,
            addBlockButton: true,
            finishBlockButton: false,
        }
    },
    created() {

        /** As we are validating each element we need to push validator object to the valdiation array for the currently
         * submitted block / data passed to the component via the values prop. Basically if we have two bloc
         * passed in, we need to count these and create two corresponding validator objects**/
        console.log('in pricing component');

        /** Firstly - let's count the legnth of the block array - new forms will be empty **/
        var length = this.blocks.length; // user defined length

        if ( length != null){
            console.log('lenth: ' + length)
            /** Simple Iterator to push a new validation object for each bloc  **/
            for(var i = 0; i < length; i++) {
                this.validations.blocks.push({
                    product_title: {
                        is_valid: true,
                        text: ''
                    },
                    offer_type: {
                        is_valid: true,
                        text: ''
                    },
                    offer_description: {
                        is_valid: true,
                        text: ''
                    }
                });
            }
        }else{
            console.log('lenth - not worked: ' + length)
        }




    },
    methods: {
        /** Function that adds a new object block to the array (which we loop through in the template) and initialise
         * the values to empty strings **/
        addBlock(){
            /** Before we add a new block we ensure the validate method passes true for the current submission **/
            if( this.validateNewBlock() ){
                /** Push new empty input object to array **/
                this.blocks.push({ product_title: '', unit_price: '', offer_price: '', offer_type: '', offer_description: '', cta_link: '', cta_link_text: '', newFeature: '', feature_list: [] });
                /** Create a corresponding validation block, here we set an is_valid checker with a message string **/
                this.validations.blocks.push({
                    product_title: {
                        is_valid: true,
                        text: ''
                    },
                    offer_type: {
                        is_valid: true,
                        text: ''
                    },
                    offer_description: {
                        is_valid: true,
                        text: ''
                    }
                });

                /** Update button display values **/
                this.finishBlockButton = true;
                this.addBlockButton = false;
            }

        },
        /** Delete the specific object from the array of block - pass iin the key of the specific object as passed
         * through in the click event **/
        deleteBlock(key){

            this.$buefy.dialog.confirm({
                title: 'Confirm delete',
                message: 'Are you sure you want to delete this section?',
                onConfirm: () => {
                    /** Find the corresponding key in the bloc array and remove it **/
                    this.blocks.splice(key,1);
                    /** Replicate this on the validation array aswell **/
                    this.validations.blocks.splice(key,1);

                    if(this.blocks.length === 0){
                        /** Update button display values **/
                        this.finishBlockButton = false;
                        this.addBlockButton = true;
                    }

                    //For sanity - lets ensure we emit to update the parent object - like we do on save
                    this.$emit('getFeaturesBlockDetails', this.blocks);

                    flash('Pricing card removed', 'danger');
                }
            })

        },
        validateNewBlock(){
            /** Lets create a new valid form variable which we can set within the loop for each nested
             * bloc object **/
            let validNewBlockForm = true;

            /** Ensure all block entered are valid - loop through current bloc array **/
            for( var index in this.blocks ) {

                /** Lets sanity check first to make sure object contains the property with this name of the argument **/
                if (this.blocks.hasOwnProperty( index ) ) {
                    /** Ensure a name has been entered  - simple check on empty string **/
                    if( this.blocks[index].product_title.trim() == '' ){
                        /** If empty set form variable to false and insert error message **/
                        validNewBlockForm = false;
                        this.validations.blocks[index].product_title.is_valid = false;
                        this.validations.blocks[index].product_title.text = 'Please enter a title';
                    }else{
                        /** Otherwise form in valid and we can return true (at bottom of loop) **/
                        this.validations.blocks[index].product_title.is_valid = true;
                        this.validations.blocks[index].product_title.text = '';
                    }
                }


                if (this.blocks.hasOwnProperty( index ) ) {
                    /** Ensure an address has been entered */
                    if( this.blocks[index].offer_type.trim() == '' ){
                        validNewBlockForm = false;
                        this.validations.blocks[index].offer_type.is_valid = false;
                        this.validations.blocks[index].offer_type.text = 'Type can\'t be empty';
                    }else{
                        this.validations.blocks[index].offer_type.is_valid = true;
                        this.validations.blocks[index].offer_type.text = '';
                    }
                }

                if (this.blocks.hasOwnProperty( index ) ) {
                    /** Ensure an address has been entered */
                    if( this.blocks[index].offer_description.trim() == '' ){
                        validNewBlockForm = false;
                        this.validations.blocks[index].offer_description.is_valid = false;
                        this.validations.blocks[index].offer_description.text = 'Short description can\'t be empty';
                    }else{
                        this.validations.blocks[index].offer_description.is_valid = true;
                        this.validations.blocks[index].offer_description.text = '';
                    }
                }


            }
            /** Once validation passes we return true **/
            return validNewBlockForm;
        },
        /** This is called on save - which emits the data to the parent form and resets the buttons **/
        saveNewBlock(){
            if( this.validateNewBlock() ){
                this.addBlockButton = true;
                this.finishBlockButton = false;
                /** This function is key - if we don't call it our parent form/component will not be able to access
                 * the data - VueJS Props and Emit - Props down from parent to child Emit up from Child to Parent **/
                this.$emit('getPricingBlockDetails', this.blocks);

                flash('Feature card added', 'success');
            }
        },
        addFeature(key) {
            if (this.blocks[key].newFeature.trim() != '')
                this.blocks[key].feature_list.push({
                    name: this.blocks[key].newFeature,
                });
            this.blocks[key].newFeature = '';
        },
        deleteFeature(key, ind){
            /** Find the corresponding key in the bloc array and remove it **/
            this.blocks[key].feature_list.splice(ind,1);
        }

    }
}
</script>
