<template>
    <div class="flex flex-col">
        <filter-item-component title="Categories" :items="categories" slug="categories" :filter-counts="filterCategoryCounts" class="mb-8 rounded-t-lg"></filter-item-component>
        <filter-item-component title="Deal Type" :items="deals" slug="deals" :filter-counts="filterDealCounts" class="mb-2"></filter-item-component>
    </div>
</template>

<script>
export default {
    props: {
        categories: {
            required: true,
            type: Array
        },
        deals: {
            required: true,
            type: Array
        },
        filterCategoryCounts: {
            required: true,
            type: Array
        },
        filterDealCounts: {
            required: true,
            type: Array
        }
    }
}
</script>

<style scoped>

</style>
