import { isArray, isObject, isEmpty, sample } from 'lodash'

export default {
	methods: {
		getGlideImage (image, width = 500, height = 500, fit = 'crop') {
			let url = isObject(image) ? (image?.path || image?.[0]?.path || image?.id) : (isArray(image) ? (image?.[0]?.id || image?.[0]) : image)
			let type = 'asset'
			let converted = false
			const format = 'webp'

			if (!isEmpty(url)) {
				if (url.includes('::')) {
					url = url.replace('::', '/')
					converted = true
				}
				if (url.includes('http://') || url.includes('https://')) {
					type = 'http'
				}
				if (type === 'asset') {
					return `/img/asset/${btoa(converted ? url : `images/${url}`)}?w=${width}&h=${height}&fm=${format}&fit=${fit}`
				}
				return `/img/${type}/${btoa(url)}?w=${width}&h=${height}&fm=${format}&fit=${fit}`
			}
			return '/placeholder.png'
		},

		pickRandomImage (input) {
			if (!isArray(input)) {
				return input
			}
			return sample(input)
		}
	}
}
