<template>
	<article class="shadow-lg rounded-lg overflow-hidden bg-white">
		<a :href="`/trade/${entry.slug}`" class="block xl:aspect-w-1 xl:aspect-h-2">
			<div>
				<div class="aspect-w-1 aspect-h-1">
					<div class="bg-gray-200 overflow-hidden">
						<template v-if="entry.company_logo">
							<img
								:src="getGlideImage(entry.company_logo_path, 322, 322, 'crop')"
								aria-hidden="true"
								class="absolute top-0 left-0 w-full h-full object-cover blur-lg opacity-25 z-1"
								loading="lazy"
								width="322px"
								height="322px"
							/>
							<img
								:src="getGlideImage(entry.company_logo_path, 322, 322, 'contain')"
								:alt="entry.title || null"
								class="absolute top-0 left-0 w-full h-full object-contain z-5"
								loading="lazy"
								width="322px"
								height="322px"
							/>
						</template>
						<img
							v-else
							src="/images/logo_placeholder.png"
							alt="No logo found for this company"
							class="absolute top-0 left-0 w-full h-full object-cover"
						/>
					</div>
				</div>
				<div class="xl:aspect-w-1 xl:aspect-h-1">
					<div class="flex flex-col text-gray-900 px-6 pt-4 pb-6">
						<h2 v-if="entry.title" :title="entry.title" :alt="entry.title" class="text-2xl line-clamp-2 mb-2">{{ entry.title }}</h2>
						<div class="flex-grow text-gray-500 mb-2 2xl:mb-4">
							<p v-if="entry.business_intro_description" class="line-clamp-4 mb-0">
								{{ entry.business_intro_description }}
							</p>
						</div>
						<div v-if="locations && locations.length > 0" class="deals flex mb-2">
							<div class="mr-2">
								<fa-icon :icon="['fas', 'globe']" fixed-width />
							</div>
							<div class="flex-grow flex flex-wrap text-sm 2xl:text-base">
								<span
									v-for="(location, index) in locations"
									:key="index"
									class="mr-4"
								>{{ location.city }}</span>
							</div>
						</div>
						<div v-if="sectors && sectors.length > 0" class="categories flex mb-2">
							<div class="mr-2">
								<fa-icon :icon="['fas', 'star']" fixed-width />
							</div>
							<div class="flex-grow flex flex-wrap text-sm 2xl:text-base">
								<span
									v-for="(sector, index) in sectors"
									:key="index"
									class="mr-4"
								>{{ sector.name }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a>
	</article>
</template>
<script>
	import ShowsGlideImage from '../../mixins/ShowsGlideImage'
	export default {
		name: 'ListingSingleDomino',

		mixins: [
			ShowsGlideImage
		],

		props: {
			entry: {
				type: Object,
				required: true
			}
		},

		computed: {
			sectors () {
				return this.entry.sectors || []
			},

			locations () {
				return this.entry.listing_locations || []
			}
		}
	}
</script>
