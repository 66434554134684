<template>
	<article class="flex flex-col">
		<div class="relative aspect-w-1 aspect-h-1 xl:relative mb-4">
			<a :href="entry.permalink" class="block w-full h-full">
				<img
					v-if="entry.image"
					:src="getGlideImage(entry.image, 448, 448)"
					:alt="entry.title"
					class="absolute inset-0 w-full h-full object-cover rounded-lg"
					loading="lazy"
					width="448px"
					height="448px"
				>
			</a>
		</div>
		<div class="flex flex-col h-full px-2">
			<a :href="entry.permalink" class="block flex-grow mb-2">
				<h3 class="text-smarta-green-500 text-2xl">{{ entry.title }}</h3>
			</a>
			<div
				v-if="entry.short_description"
				class="line-clamp-3 mb-4"
			>
				{{ entry.short_description }}
			</div>
			<time v-if="entry.date" class="text-sm" :datetime="dateIso">
				{{ dateFormatted }}
			</time>
			<div v-if="entry.permalink">
				<a :href="entry.permalink">{{ buttonLabel || 'Read more' }}<span aria-hidden="true" class="text-red-600 text-2xl -mt-[6px] ml-3">⟶</span></a>
			</div>
		</div>
	</article>
</template>
<script>
import dayjs from 'dayjs'
import ShowsGlideImage from '../../mixins/ShowsGlideImage'

export default {
	name: 'AdvicePostItem',

	mixins: [
		ShowsGlideImage
	],

	props: {
		entry: {
			type: Object,
			required: true
		},

		buttonLabel: {
			type: String,
			required: true
		}
	},

	computed: {
		dateIso () {
			return dayjs(this.entry.date).toISOString()
		},

		dateFormatted () {
			return dayjs(this.entry.date).format('MMM D, YYYY')
		}
	}
}
</script>
