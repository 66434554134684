<template>
    <div>
        <form id="listingForm" @submit.prevent="submit">

            <!-- Tabs -->
            <tabs>

                <tab title="Basic Details">


                    <div class="flex flex-col md:flex-row md:flex-wrap">
                        <div class="relative w-full md:w-1/2 px-3 mb-6">
                            <div class="horizontal flex flex-col my-4">
                                <!-- Title input block -->
                                <label for="companyName" class="form_field_label">Company name*</label>
                                <input
                                    type="text"
                                    id="companyName"
                                    class="w-full form_field_input focus:outline-none"
                                    placeholder="Enter company name here..."
                                    v-model="companyName"
                                >
                                <span
                                    class="validation w-full text-sm text-red-500"
                                    v-if="errors.title"
                                    v-text="errors.title[0]">
                            </span>
                                <!-- End block -->
                            </div>
                            <div class="horizontal flex flex-col my-4">
                                <!-- Website input block -->
                                <label for="companyWebsite" class="form_field_label">Website*</label>
                                <input
                                    type="url"
                                    id="companyWebsite"
                                    class="w-full form_field_input"
                                    placeholder="Enter website url"
                                    v-model="companyWebsite"
                                >
                                <div class="text-right font-body text-sm font-bold text-smarta-blue-500">
                                    Tip: Include https:// in the url
                                </div>
                                <span
                                    class="validation w-full text-red-500"
                                    v-if="errors.company_link"
                                    v-text="errors.company_link[0]">
                                </span>
                                <!-- End block -->
                            </div>
                            <div class="horizontal flex flex-col my-4">
                                <!-- Sector input block -->
                                <label for="sector" class="form_field_label">Sector*</label>
                                <div id="sector" class="select_wrapper select-default">
                                    <select v-model="sector">
                                        <option value="" disabled>Select a sector...</option>
                                        <option
                                            v-for="option in allSectors"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>
                                <!-- End block -->
                            </div>

                            <div class="horizontal flex flex-col my-4">
                                <!-- Consumer input block -->
                                <label for="companyConsumerType" class="form_field_label">Who do you sell to?*</label>
                                <div class="select_wrapper select-default">
                                    <select v-model="companyConsumerType" id="companyConsumerType">
                                        <option value="" disabled>Select a customer type...</option>
                                        <option
                                            v-for="option in allConsumerTypes"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- End block -->
                            </div>

                        </div>
                        <div class="relative w-full md:w-1/2 px-3 mb-6">
                            <div class="horizontal items-center justify-center flex flex-col my-4 md:my-12">
                                <image-upload-component v-model="companyLogo" @removeImage="removeLogo">
                                    <div slot="activator">
                                        <div class="h-48 w-48 justify-center flex border-2 border-smarta-pink-500 border-dashed cursor-pointer hover:border-smarta-pink-300" v-if="!companyLogo">
                                            <button type="button"  class="button items-center">
                                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                                                <span class="ml-2">Upload Logo</span>
                                            </button>
                                        </div>
                                        <div v-else class="mb-3 inline-flex relative">
                                            <b-tooltip label="Click to change logo" :delay="250">
                                                <img class="cursor-pointer" :src="companyLogo.src" alt="companyLogo" style="max-width: 240px;">
                                            </b-tooltip>
                                        </div>
                                    </div>
                                </image-upload-component>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col md:flex-row md:flex-wrap">
                        <div class="relative w-full md:w-full px-3 mb-6">

                            <div class="horizontal flex flex-col my-4">
                                <!-- Headline input block -->
                                <label for="companyHeadline" class="form_field_label">Headline* <small class="ml-2">Strapline to describe company service</small></label>
                                <input
                                    type="text"
                                    id="companyHeadline"
                                    :maxlength="headlineMax"
                                    class="w-full form_field_input"
                                    placeholder="We exist, to help you grow..."
                                    v-model="companyHeadline"
                                >
                                <div class="text-right font-body text-sm font-bold text-smarta-blue-500" v-text="(companyHeadline.length) + '/' + headlineMax"></div>
                                <span
                                    class="validation w-full text-red-500"
                                    v-if="errors.companyHeadline"
                                    v-text="errors.companyHeadline[0]">
                                </span>
                                <!-- End block -->
                            </div>

                            <div class="horizontal flex flex-col my-4">
                                <!-- Intro input block -->
                                <label :for="companyShortIntro" class="form_field_label">Company intro* <small class="ml-2">Used in search listing &amp; profile intro</small></label>
                                <textarea :maxlength="introMax"  v-model="companyShortIntro" class="font-body form_field_input focus:outline-none" placeholder="A short introduction to your service offering"></textarea>
                                <div class="text-right font-body text-sm font-bold text-smarta-blue-500" v-text="(companyShortIntro.length) + '/' + introMax"></div>
                                <span
                                    class="validation w-full text-red-500"
                                    v-if="errors.companyShortIntro"
                                    v-text="errors.companyShortIntro[0]">
                                </span>
                                <!-- End block -->
                            </div>

                            <div class="horizontal flex flex-col my-4">
                                <!-- Intro input block -->
                                <label :for="companyIntro" class="form_field_label">Company description</label>
                                <wysiwyg-component :name="companyIntro"  v-model="companyIntro" class="font-body focus:outline-none" placeholder="Tell us a little about your company"></wysiwyg-component>
                                <!-- End block -->
                            </div>

                            <div class="horizontal flex flex-col my-4">
                                <!-- Multi select coverage block - https://buefy.org/documentation/select -->
                                <label for="companyCoverage" class="form_field_label">Which areas do you cover?* <small class="ml-2">Press CTRL to select more than one. </small></label>
                                <b-field>
                                    <b-select
                                        multiple
                                        native-size="8"
                                        v-model="companyCoverage">
                                        <option
                                            v-for="option in allCoverageRegions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.name }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <!-- End block -->
                            </div>

                            <!-- Beufy Accordion content to house the Social fields -->
                            <b-collapse class="card px-3 my-12" animation="slide" :open="false" aria-id="contentForSocial">
                                <!-- Accordion Component trigger to open panel -->
                                <template #trigger="props">
                                    <div class="flex justify-between py-4" role="button" aria-controls="contentForSocial">
                                        <h4 class="">Social channels</h4>
                                        <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                            <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                        </div>
                                    </div>
                                </template>

                                <!-- Social fields -->
                                <div class="flex flex-col  w-full">
                                    <p>
                                        Social handles are generated by the site from the usernames provided.
                                    </p>
                                    <p>
                                        Please only add the username of your various profiles to the listing (links with "@" or "https" are invalid).
                                    </p>
                                </div>
                                <div class="horizontal flex flex-col md:flex-row justify-between mb-4">

                                    <div class="md:w-1/2 md:mr-2">
                                        <div class="">
                                            <!-- input block -->
                                            <label class="form_field_label" for="companyInstagram">Instagram</label>
                                            <div class="flex items-center">
                                                <span class="form_field_input_pre_value">instagram.com/</span>
                                            <input class="w-full form_field_input focus:outline-none" type="text" placeholder="username" id="companyInstagram" v-model="companyInstagram">
                                            </div>
                                            <span
                                                class="validation w-full text-red-500"
                                                v-if="errors.companyInstagram"
                                                v-text="errors.companyInstagram[0]">
                                        </span>
                                            <!-- End block -->
                                        </div>
                                    </div>
                                    <div class="md:w-1/2 md:ml-2">
                                        <div class="">
                                            <!-- input block -->
                                            <label class="form_field_label" for="companyTwitter">Twitter</label>
                                            <div class="flex items-center">
                                                <span class="form_field_input_pre_value">twitter.com/</span>
                                            <input class="w-full form_field_input focus:outline-none" type="text" placeholder="username" id="companyTwitter" v-model="companyTwitter">
                                            </div>
                                            <span
                                                class="validation w-full text-red-500"
                                                v-if="errors.companyTwitter"
                                                v-text="errors.companyTwitter[0]">
                                        </span>
                                            <!-- End block -->
                                        </div>
                                    </div>
                                </div>


                                <div class="horizontal flex flex-col md:flex-row justify-between mb-4">
                                    <div class="md:w-1/2 md:mr-2">
                                        <div class="">
                                            <!-- input block -->
                                            <label class="form_field_label" for="companyFacebook">Facebook</label>
                                            <div class="flex items-center">
                                                <span class="form_field_input_pre_value">facebook.com/</span>
                                            <input class="w-full form_field_input focus:outline-none" type="text" placeholder="username" id="companyFacebook" v-model="companyFacebook">
                                            </div>
                                            <span
                                                class="validation w-full text-red-500"
                                                v-if="errors.companyFacebook"
                                                v-text="errors.companyFacebook[0]">
                                        </span>
                                            <!-- End block -->
                                        </div>
                                    </div>
                                    <div class="md:w-1/2 md:ml-2">
                                        <div class="">
                                            <!-- input block -->
                                            <label class="form_field_label" for="companyLinkedIn">LinkedIn</label>
                                            <div class="flex items-center">
                                                <span class="form_field_input_pre_value">linkedin.com/company/</span>
                                                <input class="w-full form_field_input focus:outline-none" type="text" placeholder="username" id="companyLinkedIn" v-model="companyLinkedIn">

                                            </div>

                                            <span
                                                class="validation w-full text-red-500"
                                                v-if="errors.companyLinkedIn"
                                                v-text="errors.companyLinkedIn[0]">
                                        </span>
                                            <!-- End block -->
                                        </div>
                                    </div>
                                </div>

                            </b-collapse>
                            <!-- End Beufy Panel -->

                        </div>
                    </div>
                </tab>

                <tab title="Location">

                    <div class="horizontal flex flex-col mb-4">
                        <address-component :value="currentLocations" @getLocationDetails="getLocationDetails"></address-component>
                    </div>
                </tab>

                <tab title="Products/Services">

                    <!-- Beufy Accordion content to house the Feature Alt Vue Component -->
                    <b-collapse class="card mx-3 my-12" animation="slide" :open="false" aria-id="contentForFeaturesAlt">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFeaturesAlt">
                                <h4 class="">Features block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Features Alt Component -->

                        <feature-alt-component :value="currentFeaturesAltBlockData" @updateFeaturesAltBlock="updateFeaturesAltBlockDetails"></feature-alt-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->

                    <!-- Beufy Accordion content to house the Feature Vue Component -->
                    <b-collapse class="card mx-3 mb-12" animation="slide" :open="false" aria-id="contentForFeatures">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFeatures">
                                <h4 class="">Additional Features block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <div class="relative w-full px-3 mb-6">

                            <div class="horizontal flex flex-col my-4">
                                <label class="form_field_label" for="featureBlockTitle">Feature section title</label>
                                <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Our core services" id="featureBlockTitle" v-model="featureBlockTitle">
                                <div class="text-right font-body text-sm font-bold text-smarta-blue-500" >Tip: 15 words max</div>
                            </div>
                            <div class="horizontal flex flex-col mb-4">
                                <label class="form_field_label" for="featureBlockDescription">Feature section subtitle</label>
                                <!-- Body input block -->
                                <wysiwyg-component id="featureBlockDescription" name="featureBlockDescription"  v-model="featureBlockDescription" class="font-body focus:outline-none" placeholder="We have a wide and varied service offering for all our clients..."></wysiwyg-component>
                                <div class="text-right font-body text-sm font-bold text-smarta-blue-500" >Tip: 40 words max</div>
                                <!-- End block -->
                            </div>
                        </div>
                        <!-- Features Component -->
                        <feature-component :value="currentFeaturesBlockData" @updateFeaturesBlock="updateFeaturesBlockDetails"></feature-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->

                    <!-- Beufy Accordion content to house the Comparison Table Vue Component -->
                    <b-collapse class="card mx-3 mb-12" animation="slide" :open="false" aria-id="contentForFeatureCompare">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFeatureCompare">
                                <h4 class="">Other Details</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Comparison Component -->
                        <comparison-component :value="currentComparisonBlockData" @updateComparisonBlock="updateComparisonBlockDetails"></comparison-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->


                </tab>

                <tab title="Further Details">

                    <!-- Beufy Accordion content to house the FAQ accordion Vue Component -->
                    <b-collapse class="card mx-3 my-12" animation="slide" :open="false" aria-id="contentForFAQs">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFAQs">
                                <h4 class="">FAQ block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Accordion Component -->
                        <accordion-component :value="currentAccordionBlockData" @updateAccordionBlock="updateAccordionBlockDetails"></accordion-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->


                    <!-- Beufy Accordion content to house the Testimonial Vue Component -->
                    <b-collapse class="card mx-3 mb-12" animation="slide" :open="false" aria-id="contentForTestimonials">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForTestimonials">
                                <h4 class="">Testimonials block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Testimonial Component -->
                        <testimonial-component :value="currentTestimonialBlockData" @updateTestimonialBlock="updateTestimonialBlockDetails"></testimonial-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->

                </tab>

                <tab title="Gallery">

                    <p class="mx-3 mt-4 mb-12 lead">To select more than one image at a time press hold CTRL whilst selecting images.</p>

                    <gallery-component :value="currentGalleryImages" @updateGallery="updateGalleryDetails"></gallery-component>

                </tab>

                <tab title="Sales Literature">

                    <download-component :value="currentDownloads" @updateFiles="updateFileDetails"></download-component>

                </tab>

            </tabs>

            <div class="my-8 border-t-2 border-smarta-pink-500 border-dashed mx-3"></div>

            <!-- Error Bag -->
            <div v-if="errorsExist" >
                <div class="relative mx-3 py-2 mb-4">
                    <button class="absolute top-0 right-0 mr-2 -mt-6" type="button" @click="closeValdiationBlock">
                        <svg class="w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </button>
                    <div v-for="(v, k) in errors" :key="k">
                        <div class="bg-white flex items-center mb-3">
                            <div class="border-l-4 p-4 border-red-500">
                                <svg class="text-red-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            </div>
                            <p v-for="error in v" :key="error" class="text-gray-500 mb-0">
                                {{ error }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- CTAs -->
            <div class="px-3">
                <button
                    type="button"
                    @click="confirmSubmission"
                    class="items-center button pink focus:outline-none mt-26"
                    :disabled="isDisabled">
                    {{ isCreatingListing ? "Updating..." : "SAVE AND FINISH" }}
                </button>
            </div>
        </form>
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>

<script>
import tabs from '../tabs'
import tab from '../tab'

//Grab data for dropdown
import coverage from './json/coverage.json';
import businessConsumers from './json/businessAudienceType.json';

export default {

    props: ['sectors'],

    components:{
        tabs,
        tab
    },
    data() {
        return {
            companyName: '',
            companyWebsite: '',
            companyInstagram: '',
            companyTwitter: '',
            companyLinkedIn: '',
            companyFacebook: '',
            companyIntro:'',
            companyShortIntro:'',
            companyHeadline: '',
            featureBlockTitle: '',
            featureBlockDescription:'',
            sector:[],
            companyLogo: null,
            allSectors: [],
            allCoverageRegions: coverage,
            companyCoverage: [],
            allConsumerTypes: businessConsumers,
            companyConsumerType: [],

            //Character restrictions
            headlineMax: 60,
            introMax: 140,

            //New/updated location data to be populate from child
            locationData: [],
            //set the location so we can populate the child location component with current value
            currentLocations: [],

            currentGalleryImages: [],
            galleryData :[],

            currentDownloads: [],
            downloadData: [],

            currentAccordionBlockData: [],
            accordionBlockData :[],

            currentTestimonialBlockData: [],
            testimonialBlockData: [],

            currentFeaturesBlockData: [],
            featuresBlockData: [],

            currentFeaturesAltBlockData: [],
            featuresBlockAltData: [],

            currentComparisonBlockData: [],
            comparisonBlockData: [],

            errors:[],
            errorsExist: false,
            canSubmit: null,


            isLoading: false,
            isFullPage: true,
            isCreatingListing: false,
            isDisabled: false,

        }
    },
    /** Created block allows us to perform some actions on load (eg populate
     * dropdown lists) **/
    created() {

        /** Interate over all available sectors array and assign to the form **/
        let getAllSectors = this.sectors.map(function(sector) {
            /** Return object **/
            return {
                value: sector.id,
                label: sector.name
            };
        });

        /** Push all sectors to form **/
        this.allSectors = getAllSectors;

    },
    methods: {
        removeLogo(){
            this.companyLogo = null;
        },
        /** Get the data passed (emitted from the location/address component and listened for on location include ) **/
        getLocationDetails(locationData) {
            /** Set the data passed from the child to our location object - we can then use this to pass
            * to controller with our global form object created in the submit method. **/
            this.locationData = locationData;
        },
        updateGalleryDetails(galleryImages) {
            this.galleryData = galleryImages;
        },
        updateFileDetails(downloadFiles) {
            this.downloadData = downloadFiles;
        },
        updateAccordionBlockDetails(accordionBlockData) {
            this.accordionBlockData = accordionBlockData;
        },
        updateTestimonialBlockDetails(testimonialBlockData) {
            this.testimonialBlockData = testimonialBlockData;
        },
        updateFeaturesBlockDetails(featureBlockData) {
            this.featuresBlockData = featureBlockData;
        },
        updateFeaturesAltBlockDetails(featureAltBlockData) {
            this.featuresBlockAltData = featureAltBlockData;
        },
        updateComparisonBlockDetails(comparisonsBlockData) {
            this.comparisonBlockData = comparisonsBlockData;
        },
        closeValdiationBlock(){
            this.errorsExist = false;
            this.errors = {};
        },
        /** This allows us to have a confirm modal pop up on button click - if
         * confirm we run the submission + VALIDATIONS CLIENT SIDE **/
        confirmSubmission(){

            //Let's do some pre validation before a submission - client specifies minimum required fields

            //Set initial error state + bag to empty
            this.errorsExist = false;
            this.errors = [];

            //Set init value to true - if all validation passes - this value remains true & we can open the confirm dialog
            this.canSubmit = true;

            //Then lets check some null/empty values - if empty - we push an error object to error array

            //Company name
            if (!this.companyName) {
                //Show the error block
                this.errorsExist = true;
                //Push the error the array that we will iterate over
                this.errors.push({
                    "company_name" : "Company name is required"
                });
                //This value will now stop the confirm dialog popping up until we have fixed the validation error
                this.canSubmit = false;
            }

            //Company website
            if (!this.companyWebsite) {
                this.errorsExist = true;
                this.errors.push({
                    "company_link" : "Company website is required"
                });
                this.canSubmit = false;
            }

            //Company sector
            if (this.sector.length === 0) {
                this.errorsExist = true;
                this.errors.push({
                    "company_sector" : "Company sector is required"
                });
                this.canSubmit = false;
            }

            //Business consumer type
            if (this.companyConsumerType.length === 0) {
                this.errorsExist = true;
                this.errors.push({
                    "company_customer_type" : "Company customer type is required"
                });
                this.canSubmit = false;
            }

            //Company headline
            if (!this.companyHeadline) {
                this.errorsExist = true;
                this.errors.push({
                    "company_headline" : "Company headline is required"
                });
                this.canSubmit = false;
            }

            //Company intro
            if (!this.companyShortIntro) {
                this.errorsExist = true;
                this.errors.push({
                    "company_short_intro" : "Company intro is required"
                });
                this.canSubmit = false;
            }

            //Company coverage - multiple saved as array so length check
            if (this.companyCoverage.length === 0) {
                this.errorsExist = true;
                this.errors.push({
                    "company_coverage" : "Company coverage is required"
                });
                this.canSubmit = false;
            }

            //Company coverage - multiple saved as array so length check
            if (this.currentLocations.length === 0) {
                this.errorsExist = true;
                this.errors.push({
                    "company_location" : "Minimum of 1 location is required. Please click the Location tab at the top of the page"
                });
                this.canSubmit = false;
            }

            //We check FIRST is we CAN submit - IE no errors or empty states in the required fields
            if (this.canSubmit === true) {
                console.log("Confirm submission");
                this.$buefy.dialog.confirm({
                    title: 'Confirm Submission',
                    message: 'Please note that once submitted, this listing will be locked until approved',
                    onConfirm: () => this.submit()
                })

            }else{
                console.log("Sorry, there was an issue with your submission");
            }

        },

        /** This is our Axios (Ajax) submission to the controller so we can save our form data into the
         * correct models **/
        async submit() {
            console.log('Submit confirm');
            /** Set some initial flags **/
            this.isCreatingListing = true;
            this.isDisabled = true;

            this.isLoading = true;

            /** Create new form object (needed for handling files/images) **/
            let formData = new FormData();


            //ADD DATA TO THE NEW FORM OBJECT

            formData.append("title", this.companyName);
            formData.append("company_link", this.companyWebsite);
            formData.append("business_description_long", this.companyIntro);
            formData.append("business_intro_description", this.companyShortIntro);

            formData.append("business_headline", this.companyHeadline);
            formData.append("business_sector", this.sector);
            formData.append("company_type", this.companyConsumerType);


            formData.append("company_link_instagram", this.companyInstagram);
            formData.append("company_link_twitter", this.companyTwitter);
            formData.append("company_link_linkedin", this.companyLinkedIn);
            formData.append("company_link_facebook", this.companyFacebook);


            formData.append("feature_block_title", this.featureBlockTitle);
            formData.append("feature_block_body", this.featureBlockDescription);

            formData.append("company_coverage", this.companyCoverage);

            /** 1. We need to grab the data from the object and stringify for the request **/
            let feature_block = JSON.stringify(this.currentFeaturesBlockData);
            /** 2. Then append to the form - only if not empty **/
            if(this.currentFeaturesBlockData.length !== 0){
                formData.append("feature_block", feature_block);
            }

            let feature_block_alt = JSON.stringify(this.currentFeaturesAltBlockData);
            if(this.currentFeaturesAltBlockData.length !== 0){
                formData.append("feature_block_alt", feature_block_alt);
            }

            let feature_comparison_block = JSON.stringify(this.currentComparisonBlockData);

            if(this.currentComparisonBlockData.length !== 0){
                formData.append("comparison_block", feature_comparison_block);
            }

            let accordion_block = JSON.stringify(this.currentAccordionBlockData);
            if(this.currentAccordionBlockData.length !== 0){
                formData.append("accordion_block", accordion_block);
            }

            let testimonial_block = JSON.stringify(this.currentTestimonialBlockData);
            if(this.currentTestimonialBlockData.length !== 0) {
                formData.append("testimonial_block", testimonial_block);
            }

            let company_addresses = JSON.stringify(this.currentLocations);
            if(this.currentLocations.length !== 0) {
                formData.append("company_addresses", company_addresses);
            }

            //Company Logo
            if(this.companyLogo !== null) {
                formData.append("company_logo", this.companyLogo.file);
            }
            //NOW LETS GET THE GALLERY IMAGES APPENDED
            if(this.galleryData.length !== 0) {
                this.galleryData.forEach(function (data) {
                    formData.append('gallery_images[]', data.file);
                });
            }

            if(this.downloadData.length !== 0) {
                this.downloadData.forEach(function (data) {
                    formData.append('listing_downloads[]', data.file);
                });
            }


            //Send data to URl to update post along with the form data
            api.post("/listings", formData, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .catch(
                    this.onFail.bind(this)
                )
                .then(
                    response => {
                        this.submitted = true;
                        this.isLoading = true;
                        this.errors = [];
                        location = response.data.message;
                    }
                );

        },
        /** Here we Look for errors and return to the catch block  **/
        onFail(error){
            this.errorsExist = true;
            flash('Opps, something went wrong', 'danger');
            this.errors = error.response.data.errors || [];
            this.submitted = false;
            this.isCreatingListing = false;
            this.isLoading = false;
            this.isDisabled = false;
            throw error;
        }
    },
}
</script>
