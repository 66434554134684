import { findIndex } from 'lodash'

const state = {
    items: []
}

const getters = {
    items: (state) => {
        return state.items
    },

    count: (state) => {
        return state.items.length
    },

    isComparing: (state) => {
        return state.items.length > 0
    }
}

const mutations = {
    add (state, item) {
        state.items.push(item)
    },

    remove (state, id) {
        const index = findIndex(state.items, { id })
        if (index >= 0) {
            state.items.splice(index, 1)
        }
    },

    clear (state) {
        state.items = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
