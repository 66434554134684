<template>
    <div id="listing_accordion">
        <!-- Here we want to loop through all the bloc within the bloc array
        and we want to ident each one via the key - this helps us identify the specific object-->

        <div class="px-3 mb-6" v-if="blockActive">

            <h3 >Testimonial section</h3>
        </div>

        <div class="flex flex-col md:flex-row md:flex-wrap">



            <div class="relative w-full md:w-1/2 px-3 mb-6" v-for="(block, key) in blocks"  v-bind:key="key">
                <div class="absolute top-0 right-0 mr-3">
                    <b-tooltip label="Remove block" :delay="250">
                            <span class="cursor-pointer" @click="deleteBlock(key)" title="Remove block">
                            <svg class="w-5 h-5 text-smarta-pink-500 hover:text-smarta-pink-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </span>
                    </b-tooltip>
                </div>

                <div class="horizontal flex flex-col my-4">
                    <label class="form_field_label" for="name">{{key+1}}. Name</label>
                    <input class="w-full form_field_input focus:outline-none" type="text" placeholder="John Doe" id="name" v-model="blocks[key].name" >
                    <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.blocks[key].name.is_valid">{{ validations.blocks[key].name.text }}</span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label class="form_field_label" for="position">Position</label>
                    <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Managing Partner" id="position" v-model="blocks[key].position" >
                    <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.blocks[key].position.is_valid">{{ validations.blocks[key].position.text }}</span>
                </div>
                <div class="horizontal flex flex-col my-4">
                    <label class="form_field_label" for="company">Company</label>
                    <input class="w-full form_field_input focus:outline-none" type="text" placeholder="John Doe Flooring" id="company" v-model="blocks[key].company" >
                    <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.blocks[key].company.is_valid">{{ validations.blocks[key].company.text }}</span>
                </div>
                <div class="horizontal flex flex-col mb-4">
                    <label class="form_field_label"  :for="`quote_${key+1}`">Quote</label>
                    <!-- Body input block -->
                    <wysiwyg-component :id="`quote_${key+1}`" :name="`quote_${key+1}`" v-model="blocks[key].quote" class="font-body focus:outline-none" placeholder="We can not recommend Smarta enough..."></wysiwyg-component>
                    <!-- End block -->
                    <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.blocks[key].quote.is_valid">{{ validations.blocks[key].quote.text }}</span>
                </div>


            </div>
        </div>
        <div class="button_group flex flex-row px-3 mb-6">
            <!-- We have set the default button states in the data below, if true show, if false hide -->
            <button type="button" class="button green" v-if="!blockActive" @click="addBlockSection">Add Testimonial section</button>
            <button type="button"  class="button green" v-if="addBlockButton" @click="addBlock">Add another testimonial</button>
            <button type="button"  class="button pink" v-if="finishBlockButton" @click="saveNewBlock">Save quote</button>
        </div>

    </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<script>
export default {
    /** Prop value will allows us to retrieve currently set and populated data **/
    props: ['value'],
    /** Data sets our array for bloc objects + corresponding validation array, plus sets a couple of button
     * defaults **/
    data(){
        return {
            blocks:this.value,
            validations: {
                blocks: [
                ],
            },
            blockActive: false,
            addBlockButton: false,
            finishBlockButton: false,

            completed: false
        }
    },
    created() {

        /** As we are validating each element we need to push validator object to the valdiation array for the currently
         * submitted block / data passed to the component via the values prop. Basically if we have two bloc
         * passed in, we need to count these and create two corresponding validator objects**/

        /** Firstly - let's count the legnth of the block array - new forms will be empty **/
        var length = this.blocks.length; // user defined length

        if ( length != null){

            /** Simple Iterator to push a new validation object for each bloc  **/
            for(var i = 0; i < length; i++) {
                this.validations.blocks.push({
                    name: {
                        is_valid: true,
                        text: ''
                    },
                    position: {
                        is_valid: true,
                        text: ''
                    },
                    company: {
                        is_valid: true,
                        text: ''
                    },
                    quote: {
                        is_valid: true,
                        text: ''
                    }
                });
            }

        }else{

        }



    },
    methods : {
        addBlockSection(){
            this.blockActive = true;
            this.addBlockButtonSection = false;
            this.addBlockButton = false;
            this.addBlock();
        },
        /** Function that adds a new object block to the array (which we loop through in the template) and initialise
         * the values to empty strings **/
        addBlock(){
            /** Before we add a new block we ensure the validate method passes true for the current submission **/
            if( this.validateNewBlock() ){
                /** Push new empty input object to array **/
                this.blocks.push({ name: '', position: '', company: '', quote: ''});
                /** Create a corresponding validation block, here we set an is_valid checker with a message string **/
                this.validations.blocks.push({
                    name: {
                        is_valid: true,
                        text: ''
                    },
                    position: {
                        is_valid: true,
                        text: ''
                    },
                    company: {
                        is_valid: true,
                        text: ''
                    },
                    quote: {
                        is_valid: true,
                        text: ''
                    }
                });

                /** Update button display values **/
                this.finishBlockButton = true;
                this.addBlockButton = false;
            }

        },
        /** Delete the specific object from the array of block - pass iin the key of the specific object as passed
         * through in the click event **/
        deleteBlock(key){

            this.$buefy.dialog.confirm({
                title: 'Confirm delete',
                message: 'Are you sure you want to delete this section?',
                onConfirm: () => {

                    /** Find the corresponding key in the bloc array and remove it **/
                    this.blocks.splice(key,1);
                    /** Replicate this on the validation array aswell **/
                    this.validations.blocks.splice(key,1);

                    //For sanity - lets ensure we emit to update the parent object - like we do on save
                    this.$emit('getTestimonialBlockDetails', this.blocks);

                    flash('Testimonial removed', 'danger');
                }
            })

            if(this.blocks.length === 0){
                /** Update button display values **/
                this.finishBlockButton = false;
                this.addBlockButton = true;
            }
        },
        validateNewBlock(){
            /** Lets create a new valid form variable which we can set within the loop for each nested
             * bloc object **/
            let validNewBlockForm = true;

            /** Ensure all block entered are valid - loop through current bloc array **/
            for( var index in this.blocks ) {

                if (this.blocks.hasOwnProperty( index ) ) {
                    /** Ensure an address has been entered */
                    if( this.blocks[index].quote.trim() == '' ){
                        validNewBlockForm = false;
                        this.validations.blocks[index].quote.is_valid = false;
                        this.validations.blocks[index].quote.text = 'Testimonial must have a quote';
                    }else{
                        this.validations.blocks[index].quote.is_valid = true;
                        this.validations.blocks[index].quote.text = '';
                    }
                }

            }
            /** Once validation passes we return true **/
            return validNewBlockForm;
        },
        /** This is called on save - which emits the data to the parent form and resets the buttons **/
        saveNewBlock(){
            if( this.validateNewBlock() ){
                this.addBlockButton = true;
                this.finishBlockButton = false;
                /** This function is key - if we don't call it our parent form/component will not be able to access
                 * the data - VueJS Props and Emit - Props down from parent to child Emit up from Child to Parent **/
                this.$emit('getBlockDetails', this.blocks);

                flash('Testimonial added', 'success');
            }
        }
    }
}
</script>
