<template>
    <section id="prepend" class="prepend" >

    </section>
</template>

<script>
export default {
    created() {


    },
    mounted() {


        // $('#external').load(function(){
        //     //lets do something when is loaded
        //     if($(element).is(':visible')){
        //         console.log('element is visible. do stuff.');
        //     }
        // });
        //
        // setTimeout(() => {
        //
        //
        //     var links = document.getElementById("hbspt-form-1618589025234-5226254636").innerHTML();
        //     console.log(links);
        //     document.getElementById('hbspt-form-1618589025234-5226254636').appendTo(
        //         document.getElementById('prepend')
        //
        //     );
        // }, 8000);


    }
    ,
    methods: {

    },
}
</script>
