<template>
    <div>
        <b-collapse class="panel" animation="slide" v-model="isOpen">
            <template #trigger>
                <h5 class="p-3 text-white flex justify-between uppercase  rounded-t-lg">{{ title }}
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                </h5>
            </template>
            <div class="pt-4 bg-white">
                <div v-for="item in items" class="flex flex-row">
                    <div class="w-1/6 text-center">
                        <input @change="updateFilters" v-model="item.selected" :id="item.slug" type="checkbox" :name="item.slug">
                    </div>
                    <label class="w-2/3" :for="item.slug">{{ item.name }}</label>
                    <p class="w-1/6 text-center">({{ productCount(item) }})</p>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: true,
            type: String
        },
        items: {
            required: true,
            type: Array
        },
        slug: {
            required: true,
            type: String
        },
        filterCounts: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            isOpen: true
        }
    },
    methods: {
        updateFilters() {
            this.$root.$emit('updateFilters', {
                slug: this.slug,
                items: this.items
            });
        },
        productCount(item) {
            for (let i = 0; i < this.filterCounts.length; i++) {
                if (this.filterCounts[i].pivot_id == item.id) {
                    return this.filterCounts[i].filterCount;
                }
            }

            return "0";
        }
    },
    mounted(){
        if(window.matchMedia("(max-width: 700px)").matches){
            // The viewport is less than 700px wide
            this.isOpen = false;
        } else{
            // The viewport is at least 700px wide
            this.isOpen = true;
        }
    }
}
</script>

<style scoped>
h5 {
    background: #172945;
}
</style>
