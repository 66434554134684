var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:{
		'shadow-lg rounded-lg overflow-hidden bg-white': true,
		'order-first': _vm.beingCompared
	}},[_c('a',{staticClass:"block xl:aspect-w-1 xl:aspect-h-2",attrs:{"href":("/products/" + (_vm.entry.slug))}},[_c('div',[_c('div',{staticClass:"aspect-w-1 aspect-h-1"},[_c('div',{staticClass:"bg-gray-400"},[(_vm.entry.product_image)?_c('img',{staticClass:"absolute top-0 left-0 w-full h-full object-cover",attrs:{"src":_vm.getGlideImage(_vm.entry.product_image_path, 322, 322),"alt":_vm.entry.title || null,"loading":"lazy","width":"322px","height":"322px"}}):_vm._e(),_vm._v(" "),(_vm.compare)?_c('button',{class:{
							'group absolute top-0 right-0 px-2 py-2': true,
							'pointer-events-none': _vm.maxComparedReached && !_vm.beingCompared
						},attrs:{"disabled":_vm.maxComparedReached && !_vm.beingCompared,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleCompare(_vm.entry)}}},[_c('div',{class:{
								'flex items-center justify-center rounded-full shadow w-8 h-8 transition-all': true,
								'text-white bg-smarta-green-500 opacity-100': _vm.beingCompared,
								'text-smarta-green-500 group-hover:text-white bg-white group-hover:bg-smarta-green-500 opacity-100': !_vm.beingCompared && !_vm.maxComparedReached,
								'opacity-0': !_vm.beingCompared && _vm.maxComparedReached
							}},[_c('fa-icon',{attrs:{"icon":['fas', 'right-left']}})],1)]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"xl:aspect-w-1 xl:aspect-h-1"},[_c('div',{staticClass:"flex flex-col text-gray-900 px-6 pt-4 pb-6"},[(_vm.entry.title)?_c('h2',{staticClass:"text-2xl line-clamp-2 mb-2",attrs:{"title":_vm.entry.title,"alt":_vm.entry.title}},[_vm._v(_vm._s(_vm.entry.title))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-grow text-gray-500 mb-2 2xl:mb-4"},[(_vm.entry.product_description)?_c('p',{class:{
								'line-clamp-2 xl:line-clamp-4 mb-0': true,
								'line-clamp-4': !_vm.entry.trustpilot || (_vm.entry.trustpilot && _vm.entry.trustpilot.rating === null)
							}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.entry.product_description)+"\n\t\t\t\t\t\t")]):_vm._e()]),_vm._v(" "),(_vm.categories && _vm.categories.length > 0)?_c('div',{staticClass:"categories flex mb-2"},[_c('div',{staticClass:"mr-2"},[_c('fa-icon',{attrs:{"icon":['fas', 'star'],"fixed-width":""}})],1),_vm._v(" "),_c('div',{staticClass:"flex-grow flex flex-wrap text-sm 2xl:text-base"},_vm._l((_vm.categories),function(category,index){return _c('span',{key:index,staticClass:"mr-4"},[_vm._v(_vm._s(category.name))])}),0)]):_vm._e(),_vm._v(" "),(_vm.deals && _vm.deals.length > 0)?_c('div',{staticClass:"deals flex mb-2"},[_c('div',{staticClass:"mr-2"},[_c('fa-icon',{attrs:{"icon":['fas', 'tag'],"fixed-width":""}})],1),_vm._v(" "),_c('div',{staticClass:"flex-grow flex flex-wrap text-sm 2xl:text-base"},_vm._l((_vm.deals),function(deal,index){return _c('span',{key:index,staticClass:"mr-4"},[_vm._v(_vm._s(deal.name))])}),0)]):_vm._e(),_vm._v(" "),_c('TrustPilot',{staticClass:"mt-2",attrs:{"entry":_vm.entry,"show-missing":false}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }