<template>
    <div id="listing_comparison">
        <!-- Here we want to loop through all the bloc within the bloc array
        and we want to ident each one via the key - this helps us identify the specific object-->

        <div class="px-3 mb-6" v-if="blockActive">
            <h3>Feature list</h3>
        </div>

        <div class="flex flex-col px-3">


            <div class="relative w-full px-3" v-for="(block, key) in blocks"  v-bind:key="key">

                <div class="horizontal flex flex-col my-2">
                    <label class="form_field_label" for="feature_row">{{key+1}}. Feature</label>
                    <div class="flex items-center">
                        <div class="flex-1">
                            <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Feature..." id="name" v-model="blocks[key].feature_row" >
                            <span class="validation w-full text-red-600 text-italic font-bold text-xs" v-show="!validations.blocks[key].feature_row.is_valid">{{ validations.blocks[key].feature_row.text }}</span>

                        </div>
                        <div class="ml-3 relative">
                            <b-tooltip label="Remove block" :delay="250">
                            <span class="cursor-pointer" @click="deleteBlock(key)" title="Remove block">
                            <svg class="w-5 h-5 text-smarta-pink-500 hover:text-smarta-pink-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </span>
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="button_group flex flex-row px-3 my-6">
            <!-- We have set the default button states in the data below, if true show, if false hide -->
            <button type="button"  class="button green" v-if="addBlockButton" @click="addBlock">Add row</button>
            <button type="button"  class="button pink" v-if="finishBlockButton" @click="saveNewBlock">Save</button>
        </div>

    </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<script>
export default {
    /** Prop value will allows us to retrieve currently set and populated data **/
    props: ['value'],
    /** Data sets our array for bloc objects + corresponding validation array, plus sets a couple of button
     * defaults **/
    data(){
        return {
            blocks:this.value,
            validations: {
                blocks: [
                ],
            },
            blockActive: false,
            addBlockButton: true,
            finishBlockButton: false,
        }
    },
    created() {

        /** As we are validating each element we need to push validator object to the valdiation array for the currently
         * submitted block / data passed to the component via the values prop. Basically if we have two bloc
         * passed in, we need to count these and create two corresponding validator objects**/

        /** Firstly - let's count the legnth of the block array - new forms will be empty **/
        var length = this.blocks.length; // user defined length

        if ( length != null){

            /** Simple Iterator to push a new validation object for each bloc  **/
            for(var i = 0; i < length; i++) {
                this.validations.blocks.push({
                    feature_row: {
                        is_valid: true,
                        text: ''
                    }
                });
            }

        }else{

        }


    },
    methods : {
        addBlockSection(){
            this.blockActive = true;
            this.addBlockButtonSection = false;
            this.addBlockButton = false;
            this.addBlock();
        },
        /** Function that adds a new object block to the array (which we loop through in the template) and initialise
         * the values to empty strings **/
        addBlock(){
            /** Before we add a new block we ensure the validate method passes true for the current submission **/
            if( this.validateNewBlock() ){
                /** Push new empty input object to array **/
                this.blocks.push({ feature_row: ''});
                /** Create a corresponding validation block, here we set an is_valid checker with a message string **/
                this.validations.blocks.push({
                    feature_row: {
                        is_valid: true,
                        text: ''
                    }
                });

                /** Update button display values **/
                this.finishBlockButton = true;
                this.addBlockButton = false;
            }

        },
        /** Delete the specific object from the array of block - pass iin the key of the specific object as passed
         * through in the click event **/
        deleteBlock(key){

            this.$buefy.dialog.confirm({
                title: 'Confirm delete',
                message: 'Are you sure you want to delete this feature?',
                onConfirm: () => {
                    /** Find the corresponding key in the bloc array and remove it **/
                    this.blocks.splice(key,1);
                    /** Replicate this on the validation array aswell **/
                    this.validations.blocks.splice(key,1);

                    if(this.blocks.length === 0){
                        /** Update button display values **/
                        this.finishBlockButton = false;
                        this.addBlockButton = true;
                    }

                    //For sanity - lets ensure we emit to update the parent object - like we do on save
                    this.$emit('getComparisonBlockDetails', this.blocks);

                    flash('Feature removed', 'danger');
                }
            })

        },
        validateNewBlock(){
            /** Lets create a new valid form variable which we can set within the loop for each nested
             * bloc object **/
            let validNewBlockForm = true;

            /** Ensure all block entered are valid - loop through current bloc array **/
            for( var index in this.blocks ) {

                /** Lets sanity check first to make sure object contains the property with this name of the argument **/
                if (this.blocks.hasOwnProperty( index ) ) {
                    /** Ensure a name has been entered  - simple check on empty string **/
                    if( this.blocks[index].feature_row.trim() == '' ){
                        /** If empty set form variable to false and insert error message **/
                        validNewBlockForm = false;
                        this.validations.blocks[index].feature_row.is_valid = false;
                        this.validations.blocks[index].feature_row.text = 'Can\'t be empty';
                    }else{
                        /** Otherwise form in valid and we can return true (at bottom of loop) **/
                        this.validations.blocks[index].feature_row.is_valid = true;
                        this.validations.blocks[index].feature_row.text = '';
                    }
                }

            }
            /** Once validation passes we return true **/
            return validNewBlockForm;
        },
        /** This is called on save - which emits the data to the parent form and resets the buttons **/
        saveNewBlock(){
            if( this.validateNewBlock() ){
                this.addBlockButton = true;
                this.finishBlockButton = false;
                /** This function is key - if we don't call it our parent form/component will not be able to access
                 * the data - VueJS Props and Emit - Props down from parent to child Emit up from Child to Parent **/
                this.$emit('getComparisonBlockDetails', this.blocks);

                flash('Feature added', 'success');
            }
        }
    }
}
</script>
