import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle, faRocketLaunch, faHandHoldingSeedling, faVideoPlus, faUserRobot, faLightbulbDollar, faMagnet, faTools, faPollPeople, faBullseyeArrow, faUserChart, faFaceLaugh, faLightbulb, faLightbulbOn, faScaleBalanced, faUsers, faCircleUser, faSatelliteDish, faArrowRight, faCircleNotch, faCircleCheck, faCircleInfo, faSortAmountDown, faBars, faFilter, faArrowDown, faTimes, faPencil, faSterlingSign, faRightLeft, faCircleChevronLeft, faCircleChevronRight, faGlobe, faPuzzlePiece, faSignOutAlt, faAddressCard, faCog, faBorderAll, faPlusCircle, faQuoteLeft, faQuoteRight, faPlayCircle, faHeart, faSearch, faTag, faStar, faChevronLeft, faChevronRight, faChevronDown, faChartBar, faClock, faMapMarkerAlt, faCalendar } from '@fortawesome/pro-solid-svg-icons'
import { faFacebookF, faLinkedinIn, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import {
	faGlobe as faGlobeRegular,
	faFaceLaugh as faFaceLaughRegular,
	faLightbulb as faLightbulbRegular,
	faLightbulbOn as faLightbulbOnRegular,
	faScaleBalanced as faScaleBalancedRegular,
	faUsers as faUsersRegular,
	faCircleUser as faCircleUserRegular,
	faSatelliteDish as faSatelliteDishRegular,
	faRocketLaunch as faRocketLaunchRegular,
	faHandHoldingSeedling as faHandHoldingSeedlingRegular,
	faVideoPlus as faVideoPlusRegular,
	faUserRobot as faUserRobotRegular,
	faLightbulbDollar as faLightbulbDollarRegular,
	faMagnet as faMagnetRegular,
	faTools as faToolsRegular,
	faPollPeople as faPollPeopleRegular,
	faBullseyeArrow as faBullseyeArrowRegular,
	faUserChart as faUserChartRegular
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
	faExclamationCircle,
	faCircleNotch,
	faCircleCheck,
	faCircleInfo,
	faBars,
	faTimes,
	faFilter,
	faLightbulb,
	faSterlingSign,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faCircleChevronLeft,
	faCircleChevronRight,
	faFacebookF,
	faLinkedinIn,
	faTwitter,
	faInstagram,
	faPencil,
	faHeart,
	faChartBar,
	faClock,
	faMapMarkerAlt,
	faCalendar,
	faTag,
	faStar,
	faSearch,
	faQuoteLeft,
	faQuoteRight,
	faPlayCircle,
	faAddressCard,
	faCog,
	faBorderAll,
	faPlusCircle,
	faSignOutAlt,
	faGlobe,
	faPuzzlePiece,
	faRightLeft,
	faArrowRight,
	faArrowDown,
	faSortAmountDown,
	faFaceLaugh,
	faLightbulb,
	faLightbulbOn,
	faScaleBalanced,
	faUsers,
	faCircleUser,
	faSatelliteDish,
	faRocketLaunch,
	faHandHoldingSeedling,
	faVideoPlus,
	faUserRobot,
	faLightbulbDollar,
	faMagnet,
	faTools,
	faPollPeople,
	faBullseyeArrow,
	faUserChart,

	faGlobeRegular,
    faFaceLaughRegular,
    faScaleBalancedRegular,
    faUsersRegular,
    faCircleUserRegular,
    faSatelliteDishRegular,
    faLightbulbRegular,
    faLightbulbOnRegular,
    faRocketLaunchRegular,
	faHandHoldingSeedlingRegular,
	faVideoPlusRegular,
	faUserRobotRegular,
	faLightbulbDollarRegular,
	faMagnetRegular,
	faToolsRegular,
	faPollPeopleRegular,
	faBullseyeArrowRegular,
	faUserChartRegular
)

Vue.component('fa-icon', FontAwesomeIcon)
