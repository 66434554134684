<template>

    <b-carousel :has-drag="drag" :arrow="arrow" :autoplay="autoPlay">
        <b-carousel-item v-for="(list, i) in items" :key="i">
            <div class="relative flex-1 flex px-3 flex-col justify-center rounded-lg overflow-auto bg-cover bg-center items-center" v-bind:style="{ 'background-image': 'url(' + list.image + ')' }">
                <div :class="list.gradient_class"></div>
                <div class="relative">
                    <div class="flex flex-col mx-auto items-center justify-center text-center md:flex-row  ">
                        <div class="p-8 xl:p-12 2xl:p-16">
                            <h1 class="text-white mb-6 text-3xl md:mt-6 xl:text-5xl">
                                {{ list.title }}
                            </h1>
                            <p class="mb-6 font-normal text-white text-lg xl:text-xl">
                                {{ list.content }}
                            </p>
                            <a :href="list.link" class="button blue mt-6 md:mb-6">{{ list.link_text }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </b-carousel-item>

    </b-carousel>

</template>

<script>
export default {
    data() {
        return {
            arrow: false,
            drag: true,
            autoPlay: true,
            items: [
                {
                    title: 'shop smarta',
                    image: '/images/holding/shop.jpg',
                    content: 'Find great value products and services from suppliers you can trust',
                    link_text: 'Find Products',
                    link: '/shop',
                    gradient_class: 'gradient_smarta_blue'
                },
                {
                    title: 'learn smarta',
                    image: '/images/holding/shop.jpg',
                    content: 'Know your stuff when it comes to business',
                    link_text: 'Sign Up Now',
                    link: '/register',
                    gradient_class: 'gradient_smarta_pink'
                },
                {
                    title: 'trade smarta',
                    image: '/images/holding/shop.jpg',
                    content: 'Find a UK independent business that you\'d like to buy from or sell to',
                    link_text: 'Search Directory',
                    link: '/trade',
                    gradient_class: 'gradient_smarta_orange'
                },
                {
                    title: 'think smarta',
                    image: '/images/holding/shop.jpg',
                    content: 'Top tips and tricks in growing a great business',
                    link_text: 'Get Free Advice',
                    link: '/think',
                    gradient_class: 'gradient_smarta_dark_blue'
                }
            ]
        }
    },
    methods: {

    }
}
</script>
<style scoped>
.is-active .al img {
    filter: grayscale(0%);
}
.al img {
    filter: grayscale(100%);
}
</style>
