<template>
    <div class="flex flex-col w-full">
        <form class="w-full" @submit.prevent="submit">
            <div class="">
                <div class="px-2">
                    <div class="mb-4">
                        <label for="current_password" class="form_field_label">Current Password</label>
                        <input
                            id="current_password"
                            type="password"
                            class="w-full form_field_input focus:outline-none"
                            name="current_password"
                            v-model="current_password"
                            :class="errors.current_password ? 'border-red-600' : 'border-muted-light'"
                            required
                        >
                    </div>
                    <div class="mb-4">
                        <label for="password" class="form_field_label">New Password</label>
                        <input
                            id="password"
                            type="password"
                            class="w-full form_field_input focus:outline-none"
                            name="password"
                            v-model="password"
                            :class="errors.password ? 'border-red-600' : 'border-muted-light'"
                            required
                        >
                    </div>
                    <div class="mb-4">
                        <label for="password_confirmation" class="form_field_label">Confirm New Password</label>
                        <input
                            id="password_confirmation"
                            type="password"
                            class="w-full form_field_input focus:outline-none"
                            name="password_confirmation"
                            v-model="password_confirmation"
                            :class="errors.password_confirmation ? 'border-red-600' : 'border-muted-light'"
                            required
                        >
                    </div>
                </div>
            </div>
            <div class="mb-4 px-2">
                <div v-if="errors_exist">
                    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <span class="text-xs italic text-red-800" v-if="errors.current_password" v-text="errors.current_password"></span>
                        <div v-for="error in errors.password">
                            <span class="validation w-full text-sm text-red-500" >{{ error }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="flex items-centre justify-end mt-12">
                <button
                    type="submit"
                    class="flex justify-center pink button"
                    :disabled="isDisabled">
                    {{ isCreatingProfile ? "Updating..." : "Update password" }}
                </button>
            </footer>
        </form>
    </div>
</template>
<style>

</style>
<script>

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            current_password: '',
            password:  '',
            password_confirmation:  '',
            isCreatingProfile: false,
            isDisabled: false,
            errors: [],
            errors_exist: false,
        };
    },
    methods: {
        submit () {
            this.isCreatingProfile = true;
            this.isDisabled = true;

            axios.patch(`/account/${this.user.id}`, {
                password: this.password,
                password_confirmation: this.password_confirmation,
                current_password: this.current_password
            })
                .then((res) => {
                    this.submitted = true
                    this.errors = {}
                    window.location = response.data.message
                })
                .catch((err) => {
                    this.errors_exist = true;
                    this.errors = err.response.data.errors || [];
                    this.submitted = false;
                    this.isCreatingProfile = false;
                    this.isDisabled = false;
                    flash('Oops, something went wrong. Please try again.', 'danger');
                })
        }
    }
}
</script>
