<template>
    <button type="button" class="items-center text-gray-500 inline-flex hover:text-red-600 focus:outline-none transition-colors mt-16" @click="deleteConfirm">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
        <span class="ml-2">Delete account</span>
    </button>
</template>
<script>
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            errors:[],
            errorsExist: false,
        }
    },

    methods: {
        deleteConfirm () {
            this.$buefy.dialog.confirm({
                title: 'Delete profile',
                message: 'Are sure sure you want to delete your profile?<br/><br/>This will remove all your data + associated listings from our systems with <strong>no chance for recovery</strong>.',
                type: 'is-danger',
                confirmText: 'Delete profile',
                focusOn: 'cancel',
                onConfirm: () => this.deleteProfile()
            })
        },

        deleteProfile () {
            axios.delete(`/account/${this.user.id}`)
                .then((res) => {
                    this.errors = {};
                    location = res.data.message;
                })
                .catch((err) => {
                    this.errors = err.response.data.errors || [];
                    flash('Oops, something went wrong. Please try again.', 'danger');
                });
        }
    }
};
</script>
