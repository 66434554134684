<template>
    <div class="inner landing-inner pb-12">

        <b-collapse
            class="py-6 faqs__item max-w-[1280px]"
            animation="slide"
            v-for="(collapse, index) of collapses"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index">
            <template #trigger="props">
                <div
                    class="flex justify-between items-center relative"
                    role="button">
                    <h4 class="text-smarta-blue text-2xl md:text-3xl lg:text-4xl pr-8">
                        {{ collapse.title }}
                    </h4>

                    <div class="absolute right-0 top-1/2 -translate-y-1/2" :class="{ 'rotate-180': props.open }">
                        <div class="border-r-4 border-b-4 border-smarta-blue w-[15px] h-[15px] rotate-45"></div>
                    </div>
                </div>
            </template>
            <div class="pt-4 pr-8">
                <p class="content" v-html="collapse.content"></p>
            </div>
        </b-collapse>

    </div>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            isOpen: null,
            collapses: this.value
        }
    }
}
</script>


