<template>
    <section class="">

        <div class="card pb-14 mb-14 relative max-w-[500px]"
            v-for="(collapse, index) of collapses"
            :key="index">
            <div
                class="card-header flex justify-start items-center rounded-lg py-2 border-smarta-blue-500"
            >
                <div v-if="collapse.type === 'module'" class="min-w-8 w-8 flex items-center">
                    <div class="inline-block relative p-2 min-w-8 min-h-8 w-8 h-8 rounded-full border-2 border-smarta-blue font-bold text-xl lg:text-2xl">
                        <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">{{ index + 1 }}</span>
                    </div>
                </div>
                <h4 v-if="collapse.title && collapse.title.length" class="inline-block text-smarta-blue text-xl lg:text-2xl font-bold" :class="{ 'pl-4 relative' : collapse.type === 'module' }">
                    {{ collapse.title }}
                </h4>

            </div>
            <div class="smarta-content">
                <div class="relative">
                    <div class="transition-all duration-500"
                         :class="{ 'max-h-[1000px] overflow-y-auto pb-8' : isOpen.includes(index), 'max-h-[100px] overflow-hidden' : !isOpen.includes(index) }"
                         v-if="collapse.type === 'induction' || collapse.type === 'conclusion'">
                        <div class="transition-all duration-500 overflow-hidden" v-html="collapse.text"></div>
                    </div>
                    <div v-else>
                        <div class="font-bold flex flex-wrap pl-12 leading-tight" v-if="collapse.hours || collapse.minutes">
                            <span class="w-full lg:w-auto inline-block text-smarta-blue">Estimated time to complete:&nbsp;</span> <span class="text-smarta-pink-500 w-full lg:w-auto">{{ courseTime(collapse.hours, collapse.minutes) }}</span>
                        </div>
                        <div class="transition-all duration-500 mt-6" :class="{ 'max-h-[1000px] overflow-y-auto pb-8' : isOpen.includes(index), 'max-h-[100px] overflow-hidden' : !isOpen.includes(index) }" v-html="collapse.content"></div>
                    </div>

                    <div class="absolute right-0 bottom-0 left-0 w-full h-full transition-all duration-500 max-h-20" :class="{ 'max-h-[0]' : isOpen.includes(index) }">
                        <div class="absolute inset-0 w-full h-full max-h-20 bg-gradient-to-b from-transparent to-white transition-opacity duration-500 opacity-100 pointer-events-none" :class="{ 'opacity-0' : isOpen.includes(index) }"></div>
                        <div class="absolute left-1/2 -translate-x-1/2 top-full flex flex-col items-center">
                            <button @click="isOpen.includes(index) ? isOpen.pop(index) : isOpen.push(index)"
                                  class="appearance-none cursor-pointer inline-block py-4 text-smarta-pink-500 font-bold text-sm relative pointer-events-auto"
                                  :class="{ 'course-modules__see-less' : isOpen.includes(index), 'course-modules__see-more' : !isOpen.includes(index) }">
                                {{ isOpen.includes(index) ? 'See less' : 'See more' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['value'],

    methods: {
        courseTime(hours = null, mins = null) {
            let hoursSet = hours && hours > 0,
                minsSet  = mins && mins > 0,
                output = '';

            if ( hoursSet && minsSet ) {
                output = hours + ' hour' + (hours > 1 ? 's' : '') + ' and ' + mins + ' mins';
            } else if ( hoursSet && !minsSet ) {
                output = hours + ' hour' + (hours > 1 ? 's' : '');
            } else if ( !hoursSet && minsSet ) {
                output = mins + ' mins';
            } else {
                return false;
            }

            return output;
        },

        moduleCount(type) {
            if ( type === 'module' && !this.moduleStart ) {
                this.moduleStart = true;
                return this.moduleIndex;
            } else if ( type === 'module' ) {
                this.moduleIndex = this.moduleIndex + 1;
                return this.moduleIndex;
            }
        }
    },

    data() {
        return {
            isOpen: [],
            collapses: this.value,
            moduleIndex: 1,
            moduleStart: false
        }
    }
}
</script>


