<template>
    <div>
        <form id="productForm" @submit.prevent="submit">
            <!-- Tabs -->
            <tabs>

                <tab title="Basic Details">

                    <div class="flex flex-col md:flex-row md:flex-wrap">
                        <div class="relative w-full md:w-1/2 px-3 mb-6">
                            <div class="horizontal flex flex-col my-4">
                                <!-- Title input block -->
                                <label for="productName" class="form_field_label">Product Name</label>
                                <input
                                    type="text"
                                    id="productName"
                                    class="w-full form_field_input focus:outline-none"
                                    placeholder="Business Account"
                                    v-model="productName"
                                >
                                <span
                                    class="validation w-full text-sm text-red-500"
                                    v-if="errors.title"
                                    v-text="errors.title[0]">
                                </span>
                                <!-- End block -->
                            </div>
                            <div class="horizontal flex flex-col my-4">
                                <!-- Title input block -->
                                <label for="productSlug" class="form_field_label">Slug</label>
                                <input
                                    type="text"
                                    id="productSlug"
                                    class="w-full form_field_input focus:outline-none"
                                    placeholder="product-slug"
                                    v-model="productSlug"
                                >
                                <span
                                    class="validation w-full text-sm text-red-500"
                                    v-if="errors.title"
                                    v-text="errors.title[0]">
                                </span>
                                <!-- End block -->
                            </div>
                            <div class="horizontal flex flex-col my-4">
                                <!-- Website input block -->
                                <label for="productBrand" class="form_field_label">Brand</label>
                                <input
                                    type="text"
                                    id="productBrand"
                                    class="w-full form_field_input"
                                    placeholder="Tide"
                                    v-model="productBrand"
                                >
                                <span
                                    class="validation w-full text-red-500"
                                    v-if="errors.brand"
                                    v-text="errors.brand[0]">
                                </span>
                                <!-- End block -->
                            </div>
                            <div class="horizontal flex flex-col my-4">
                                <!-- Type input block -->
                                <label for="productType" class="form_field_label">Product Type</label>
                                <select
                                    v-model="productType"
                                    id="productType"
                                    class="w-full form_field_input focus:outline-none"
                                >
                                    <option value="">General Product</option>
                                    <option value="book">Book</option>
                                </select>
                                <span
                                    v-if="errors.type"
                                    class="validation w-full text-sm text-red-500"
                                    v-text="errors.type[0]"
                                />
                                <!-- End block -->
                            </div>
                            <div class="horizontal flex flex-col my-4">

                                <label for="productCategories" class="form_field_label">Categories</label>
                                <b-taginput
                                    v-model="productCategories"
                                    :data="allCategories"
                                    class="input_field form_field_input"
                                    autocomplete
                                    :allow-new="false"
                                    :open-on-focus="true"
                                    field="name"
                                    placeholder="Add a category"
                                    @typing="getFilteredCategories">
                                </b-taginput>

                            </div>


                            <div class="horizontal flex flex-col my-4">

                                <label for="productOfferTypes" class="form_field_label">Deal type</label>
                                <b-taginput
                                    v-model="productOfferTypes"
                                    :data="allProductOfferTypes"
                                    autocomplete
                                    class="input_field form_field_input"
                                    :allow-new="false"
                                    :open-on-focus="true"
                                    field="name"
                                    placeholder="Add a deal type"
                                    @typing="getFilteredDeals">
                                </b-taginput>

                            </div>


                        </div>
                        <div class="relative w-full md:w-1/2 px-3 mb-6">
                            <div class="horizontal items-center justify-center flex flex-col my-4 md:my-12">
                                <image-upload-component v-model="productImage" @removeImage="removeProductImage">
                                    <div slot="activator">
                                        <div class="h-48 w-48 justify-center flex border-2 border-smarta-pink-500 border-dashed cursor-pointer hover:border-smarta-pink-300" v-if="!productImage">
                                            <button type="button"  class="button items-center">
                                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                                                <span class="ml-2">Upload Image</span>
                                            </button>
                                        </div>
                                        <div v-else class="mb-3 inline-flex relative">
                                            <b-tooltip label="Click to change logo" :delay="250">
                                                <img class="cursor-pointer" :src="productImage.src" alt="productImage" style="max-width: 240px;">
                                            </b-tooltip>
                                        </div>
                                    </div>
                                </image-upload-component>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col md:flex-row md:flex-wrap">
                        <div class="relative w-full md:w-full px-3 mb-6">
                            <div class="horizontal flex flex-col my-4">
                                <!-- Headline input block -->
                                <label for="productHeadline" class="form_field_label">Headline* <small class="ml-2">Strapline to describe product / service</small></label>
                                <input
                                    type="text"
                                    id="productHeadline"

                                    class="w-full form_field_input"
                                    placeholder="We exist, to help you grow..."
                                    v-model="productHeadline"
                                >

                                <span
                                    class="validation w-full text-red-500"
                                    v-if="errors.productHeadline"
                                    v-text="errors.productHeadline[0]">
                                </span>
                                <!-- End block -->
                            </div>

                            <div class="horizontal flex flex-col my-4">
                                <!-- Intro input block -->
                                <label :for="productIntro" class="form_field_label">Product intro* <small class="ml-2">Used in search listing &amp; product intro</small></label>
                                <textarea   v-model="productIntro" class="font-body form_field_input focus:outline-none" placeholder="A short introduction to the offer"></textarea>

                                <span
                                    class="validation w-full text-red-500"
                                    v-if="errors.productIntro"
                                    v-text="errors.productIntro[0]">
                                </span>
                                <!-- End block -->
                            </div>
                            <div class="horizontal flex flex-col my-4">
                                <!-- Intro input block -->
                                <label :for="productDescription" class="form_field_label">Product description</label>
                                <wysiwyg-component :name="productDescription"  v-model="productDescription" class="font-body focus:outline-none" placeholder="Tell us about the product offering"></wysiwyg-component>

                                <!-- End block -->
                            </div>
                        </div>
                    </div>

                </tab>

                <tab title="Offer/Pricing">

                    <!-- Beufy Accordion content to house the product link -->
                    <b-collapse class="card mx-3 my-12" animation="slide" :open="false" aria-id="contentForProductLink">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForProductLink">
                                <h4 class="">Product Link</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <div class="horizontal px-3 flex justify-between mb-4" >
                            <div class="w-1/2 mr-2">
                                <div class="">
                                    <!-- Product link input block -->
                                    <label for="productOfferLink" class="form_field_label">Product Link</label>
                                    <input
                                        type="text"
                                        id="productOfferLink"
                                        class="w-full form_field_input focus:outline-none"
                                        placeholder="Enter product link"
                                        v-model="productOfferLink"
                                    >
                                    <span
                                        class="validation w-full text-sm text-red-500"
                                        v-if="errors.product_link"
                                        v-text="errors.product_link[0]">
                                    </span>
                                    <!-- End block -->
                                </div>
                                </div>

                            <div class="w-1/2 ml-2" >
                                <div class="">
                                    <!-- Product link text input block -->
                                    <label for="productOfferLinkText" class="form_field_label">Link Text</label>
                                    <input
                                        type="text"
                                        id="productOfferLinkText"
                                        class="w-full form_field_input focus:outline-none"
                                        placeholder="Enter product link text..."
                                        v-model="productOfferLinkText"
                                    >
                                    <span
                                        class="validation w-full text-sm text-red-500"
                                        v-if="errors.product_link_text"
                                        v-text="errors.product_link_text[0]">
                                    </span>
                                    <!-- End block -->
                                </div>
                            </div>

                        </div>

                    </b-collapse>
                    <!-- End Beufy Panel -->

                    <!-- Beufy Accordion content to house the product link -->
                    <b-collapse class="card mx-3 mb-12" animation="slide" :open="false" aria-id="contentForProductPricing">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForProductPricing">
                                <h4 class="">Product pricing</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <div class="horizontal px-3 flex justify-between mb-4" >
                            <div class="w-1/2 mr-2">
                                <div class="">
                                    <!-- Product link input block -->
                                    <label for="productUnitPrice" class="form_field_label">Unit price</label>
                                    <input
                                        type="number"
                                        id="productUnitPrice"
                                        min="1"
                                        step="any"
                                        class="w-full form_field_input focus:outline-none"
                                        placeholder="Enter product link"
                                        v-model="productUnitPrice"
                                    >
                                    <span
                                        class="validation w-full text-sm text-red-500"
                                        v-if="errors.product_unit_price"
                                        v-text="errors.product_unit_price[0]">
                                    </span>
                                    <!-- End block -->
                                </div>
                            </div>

                            <div class="w-1/2 ml-2" >
                                <div class="">
                                    <!-- Product link text input block -->
                                    <label for="productOfferPrice" class="form_field_label">Offer price</label>
                                    <input
                                        type="number"
                                        id="productOfferPrice"
                                        min="1"
                                        step="any"
                                        class="w-full form_field_input focus:outline-none"
                                        placeholder="Enter product link text..."
                                        v-model="productOfferPrice"
                                    >
                                    <span
                                        class="validation w-full text-sm text-red-500"
                                        v-if="errors.product_offer_price"
                                        v-text="errors.product_offer_price[0]">
                                    </span>
                                    <!-- End block -->
                                </div>
                            </div>

                        </div>

                    </b-collapse>
                    <!-- End Beufy Panel -->

                    <!-- Beufy Accordion content to house offer timing fields -->
                    <b-collapse class="card mx-3 my-12" animation="slide" :open="false" aria-id="contentForFeaturesAlt">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFeaturesAlt">
                                <h4 class="">Offer timings (optional)</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Accordion Content -->

                    </b-collapse>
                    <!-- End Beufy Panel -->


                </tab>

                <tab title="Products/Services">

                    <!-- Beufy Accordion content to house the Pricing Table Vue Component -->
                    <b-collapse class="card mx-3 my-12" animation="slide" :open="false" aria-id="contentForPricingTable">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForPricingTable">
                                <h4 class="">Pricing Table</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Pricing table Component -->
                        <pricing-table-component :value="currentPricingBlockData" @updatePricingBlock="updatePricingBlockDetails"></pricing-table-component>

                    </b-collapse>
                    <!-- End Beufy Panel -->

                    <!-- Beufy Accordion content to house the Feature Alt Vue Component -->
                    <b-collapse class="card mx-3 my-12" animation="slide" :open="false" aria-id="contentForFeaturesAlt">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFeaturesAlt">
                                <h4 class="">Features block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Features Alt Component -->

                        <feature-alt-component :value="currentFeaturesAltBlockData" @updateFeaturesAltBlock="updateFeaturesAltBlockDetails"></feature-alt-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->


                    <!-- Beufy Accordion content to house the Comparison Table Vue Component -->
                    <b-collapse class="card mx-3 mb-12" animation="slide" :open="false" aria-id="contentForFeatureCompare">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFeatureCompare">
                                <h4 class="">Comparison table</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Comparison Component -->
                        <comparison-component :value="currentComparisonBlockData" @updateComparisonBlock="updateComparisonBlockDetails"></comparison-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->


                </tab>

                <tab title="Further Details">


                    <!-- Beufy Accordion content to house the Feature Vue Component -->
                    <b-collapse class="card mx-3 mb-12" animation="slide" :open="false" aria-id="contentForFeatures">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFeatures">
                                <h4 class="">Additional Features block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <div class="relative w-full px-3 mb-6">

                            <div class="horizontal flex flex-col my-4">
                                <label class="form_field_label" for="featureBlockTitle">Features section header</label>
                                <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Our core services" id="featureBlockTitle" v-model="featureBlockTitle">
                                <div class="text-right font-body text-sm font-bold text-smarta-blue-500" >Tip: 15 words max</div>
                            </div>
                            <div class="horizontal flex flex-col mb-4">
                                <label class="form_field_label" for="featureBlockDescription">Features prologue</label>
                                <!-- Body input block -->
                                <wysiwyg-component id="featureBlockDescription" name="featureBlockDescription"  v-model="featureBlockDescription" class="font-body focus:outline-none" placeholder="We have a wide and varied service offering for all our clients..."></wysiwyg-component>
                                <div class="text-right font-body text-sm font-bold text-smarta-blue-500" >Tip: 40 words max</div>
                                <!-- End block -->
                            </div>
                        </div>
                        <!-- Features Component -->
                        <feature-component :value="currentFeaturesBlockData" @updateFeaturesBlock="updateFeaturesBlockDetails"></feature-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->

                    <!-- Beufy Accordion content to house the FAQ accordion Vue Component -->
                    <b-collapse class="card mx-3 my-12" animation="slide" :open="false" aria-id="contentForFAQs">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForFAQs">
                                <h4 class="">FAQ block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Accordion Component -->
                        <accordion-component :value="currentAccordionBlockData" @updateAccordionBlock="updateAccordionBlockDetails"></accordion-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->


                    <!-- Beufy Accordion content to house the Testimonial Vue Component -->
                    <b-collapse class="card mx-3 mb-12" animation="slide" :open="false" aria-id="contentForTestimonials">
                        <!-- Accordion Component trigger to open panel -->
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForTestimonials">
                                <h4 class="">Testimonials block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <!-- Testimonial Component -->
                        <testimonial-component :value="currentTestimonialBlockData" @updateTestimonialBlock="updateTestimonialBlockDetails"></testimonial-component>
                    </b-collapse>
                    <!-- End Beufy Panel -->

                    <b-collapse class="card mx-3 mb-12" animation="slide" :open="false" aria-id="contentForTrustpilot">
                        <template #trigger="props">
                            <div class="flex justify-between p-4 py-4" role="button" aria-controls="contentForTrustpilot">
                                <h4>Trustpilot Block</h4>
                                <div>
                                    <span v-if="props.open">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </span>
                                    <span v-else>
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </span>
                                </div>
                            </div>
                        </template>

                        <trustpilot-component :trustpilotUrl="trustpilotUrl" @updateTrustpilotUrl="updateTrustpilotUrl"></trustpilot-component>
                    </b-collapse>
                </tab>

                <tab title="Gallery">

                    <p class="mx-3 mt-4 mb-12 lead">To select more than one image at a time press hold CTRL whilst selecting images.</p>

                    <gallery-component :value="currentGalleryImages" @updateGallery="updateGalleryDetails"></gallery-component>

                </tab>

            </tabs>

            <div class="my-8 border-t-2 border-smarta-pink-500 border-dashed mx-3"></div>

            <!-- Error Bag -->
            <div v-if="errorsExist" >
                <div class="relative mx-3 py-2 mb-4">
                    <button class="absolute top-0 right-0 mr-2 -mt-6" type="button" @click="closeValdiationBlock">
                        <svg class="w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </button>
                    <div v-for="(v, k) in errors" :key="k">
                        <div class="bg-white flex items-center mb-3">
                            <div class="border-l-4 p-4 border-red-500">
                                <svg class="text-red-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            </div>
                            <p v-for="error in v" :key="error" class="text-gray-500">
                                {{ error }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- CTAs -->
            <div class="px-3">
                <button
                    type="button"
                    @click="confirmSubmission"
                    class="items-center button pink focus:outline-none mt-26"
                    :disabled="isDisabled">
                    {{ isCreatingProduct ? "Updating..." : "SAVE AND FINISH" }}
                </button>
            </div>
        </form>
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>

<script>
import tabs from '../tabs'
import tab from '../tab'

export default {

    props: ['categories', 'deals'],

    components:{
        tabs,
        tab
    },
    data() {
        return {
            productName: '',
            productSlug: '',
            productBrand: '',

            productType: '',
            productHeadline: '',
            productIntro:'',
            productDescription:'',

            productImage: null,

            productOfferStart: new Date("2021-06-23"),
            productOfferEnd: new Date("2021-06-23"),

            productOfferLink: '',
            productOfferLinkText: '',

            productUnitPrice: '',
            productOfferPrice: '',

            allProductOfferTypes: this.deals,
            productOfferTypes: [],

            allCategories: this.categories,
            productCategories:[],

            featureBlockTitle: '',
            featureBlockDescription:'',

            currentGalleryImages: [],
            galleryData :[],

            currentAccordionBlockData: [],
            accordionBlockData :[],

            currentTestimonialBlockData: [],
            testimonialBlockData: [],

            trustpilotUrl: null,

            currentFeaturesBlockData: [],
            featuresBlockData: [],

            currentFeaturesAltBlockData: [],
            featuresBlockAltData: [],

            currentComparisonBlockData: [],
            comparisonBlockData: [],

            currentPricingBlockData: [],
            pricingBlockData: [],

            errors:[],
            errorsExist: false,
            canSubmit: null,


            isLoading: false,
            isFullPage: true,
            isCreatingProduct: false,
            isDisabled: false,

        }
    },
    /** Created block allows us to perform some actions on load (eg populate
     * dropdown lists) **/
    created() {
    },
    methods: {
        //Method to convert our categories array (of objects) and pass them into our buefy tag cloud component
        getFilteredCategories(text) {
            this.allCategories = this.categories.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            })
        },
        //Same method type for Deals
        getFilteredDeals(text) {
            this.allProductOfferTypes = this.deals.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            })
        },
        //Set field to null if image is removed
        removeProductImage(){
            this.productImage = null;
        },
        /** Get the data passed (emitted from the pricing block component and listened for on component include ) **/
        updatePricingBlockDetails(pricingBlockData) {
            /** Set the data passed from the child to our pricing object - we can then use this to pass
             * to controller with our global form object created in the submit method. **/
            this.pricingBlockData = pricingBlockData;
        },
        updateGalleryDetails(galleryImages) {
            this.galleryData = galleryImages;
        },
        updateAccordionBlockDetails(accordionBlockData) {
            this.accordionBlockData = accordionBlockData;
        },
        updateTestimonialBlockDetails(testimonialBlockData) {
            this.testimonialBlockData = testimonialBlockData;
        },
        updateTrustpilotUrl(trustpilotUrl) {
            this.trustpilotUrl = trustpilotUrl;
        },
        updateFeaturesBlockDetails(featureBlockData) {
            this.featuresBlockData = featureBlockData;
        },
        updateFeaturesAltBlockDetails(featureAltBlockData) {
            this.featuresBlockAltData = featureAltBlockData;
        },
        updateComparisonBlockDetails(comparisonsBlockData) {
            this.comparisonBlockData = comparisonsBlockData;
        },
        closeValdiationBlock(){
            this.errorsExist = false;
            this.errors = {};
        },
        /** This allows us to have a confirm modal pop up on button click - if
         * confirm we run the submission + VALIDATIONS CLIENT SIDE **/
        confirmSubmission(){

            //Let's do some pre validation before a submission - client specifies minimum required fields

            //Set initial error state + bag to empty
            this.errorsExist = false;
            this.errors = [];

            //Set init value to true - if all validation passes - this value remains true & we can open the confirm dialog
            this.canSubmit = true;

            //Then lets check some null/empty values - if empty - we push an error object to error array

            //Product name
            if (!this.productName) {
                //Show the error block
                this.errorsExist = true;
                //Push the error the array that we will iterate over
                this.errors.push({
                    "product_name" : "Product name is required"
                });
                //This value will now stop the confirm dialog popping up until we have fixed the validation error
                this.canSubmit = false;
            }


            //Product headline
            if (!this.productHeadline) {
                //Show the error block
                this.errorsExist = true;
                //Push the error the array that we will iterate over
                this.errors.push({
                    "product_headline" : "Product needs a headline"
                });
                //This value will now stop the confirm dialog popping up until we have fixed the validation error
                this.canSubmit = false;
            }

            //Product intro
            if (!this.productIntro) {
                //Show the error block
                this.errorsExist = true;
                //Push the error the array that we will iterate over
                this.errors.push({
                    "product_intro" : "Product needs an intro"
                });
                //This value will now stop the confirm dialog popping up until we have fixed the validation error
                this.canSubmit = false;
            }

            //Product link
            if (!this.productOfferLink) {
                //Show the error block
                this.errorsExist = true;
                //Push the error the array that we will iterate over
                this.errors.push({
                    "product_link" : "Product must have a link"
                });
                //This value will now stop the confirm dialog popping up until we have fixed the validation error
                this.canSubmit = false;
            }

            //Product link text
            if (!this.productOfferLinkText) {
                //Show the error block
                this.errorsExist = true;
                //Push the error the array that we will iterate over
                this.errors.push({
                    "product_link_text" : "Link text needs a value"
                });
                //This value will now stop the confirm dialog popping up until we have fixed the validation error
                this.canSubmit = false;
            }

            //Product categories
            if (this.productCategories.length === 0) {
                this.errorsExist = true;
                this.errors.push({
                    "product_categories" : "Please add a category"
                });
                this.canSubmit = false;
            }

            //Product deal types
            if (this.productOfferTypes.length === 0) {
                this.errorsExist = true;
                this.errors.push({
                    "product_categories" : "Please add a deal type"
                });
                this.canSubmit = false;
            }


            //We check FIRST is we CAN submit - IE no errors or empty states in the required fields
            if (this.canSubmit === true) {
                console.log("Confirm submission");
                this.$buefy.dialog.confirm({
                    title: 'Confirm Submission',
                    message: 'Please note that once submitted this listing will need to be approved',
                    onConfirm: () => this.submit()
                })

            }else{
                console.log("Sorry, there was an issue with your submission");
            }


        },

        /** This is our Axios (Ajax) submission to the controller so we can save our form data into the
         * correct models **/
        async submit() {
            console.log('Submit confirm');
            /** Set some initial flags **/
            this.isCreatingListing = true;
            this.isDisabled = true;

            this.isLoading = true;

            /** Create new form object (needed for handling files/images) **/
            let formData = new FormData();


            //ADD DATA TO THE NEW FORM OBJECT

            formData.append("title", this.productName);
            formData.append("brand", this.productBrand);

            formData.append("product_type", this.productType);
            formData.append("product_headline", this.productHeadline);
            formData.append("product_description", this.productIntro);
            formData.append("product_description_long", this.productDescription);

            if (this.productSlug) {
                console.log('slug set');
                formData.append("post_slug", this.productSlug);
            }else{
                console.log('slug not set');
            }

            if (this.productOfferLink) {
                console.log('set');
                formData.append("product_offer_link", this.productOfferLink);
            }else{

            }

            if (this.productOfferLinkText) {
                formData.append("product_offer_link_text", this.productOfferLinkText);
            }else{

            }

            if (this.productUnitPrice) {
                formData.append("unit_price", this.productUnitPrice);
            }else{

            }

            if (this.productOfferPrice) {
                formData.append("offer_price", this.productOfferPrice);
            }else{

            }


            this.productCategories.map(function (obj) {
                formData.append(`product_category[]`, obj.id);
            });

            this.productOfferTypes.map(function (obj) {
                formData.append(`product_offer_type[]`, obj.id);
            });


            formData.append("feature_block_title", this.featureBlockTitle);
            formData.append("feature_block_body", this.featureBlockDescription);


            /** 1. We need to grab the data from the object and stringify for the request **/
            let feature_block = JSON.stringify(this.currentFeaturesBlockData);
            /** 2. Then append to the form - only if not empty **/
            if(this.currentFeaturesBlockData.length !== 0){
                formData.append("feature_block", feature_block);
            }

            let feature_block_alt = JSON.stringify(this.currentFeaturesAltBlockData);
            if(this.currentFeaturesAltBlockData.length !== 0){
                formData.append("feature_block_alt", feature_block_alt);
            }

            let feature_comparison_block = JSON.stringify(this.currentComparisonBlockData);
            if(this.currentComparisonBlockData.length !== 0){
                formData.append("comparison_block", feature_comparison_block);
            }

            let accordion_block = JSON.stringify(this.currentAccordionBlockData);
            if(this.currentAccordionBlockData.length !== 0){
                formData.append("accordion_block", accordion_block);
            }

            let testimonial_block = JSON.stringify(this.currentTestimonialBlockData);
            if(this.currentTestimonialBlockData.length !== 0) {
                formData.append("testimonial_block", testimonial_block);
            }

            let pricing_block = JSON.stringify(this.currentPricingBlockData);
            if(this.currentPricingBlockData.length !== 0) {
                formData.append("pricing_block", pricing_block);
            }


            //Product image
            if(this.productImage !== null) {
                formData.append("product_image", this.productImage.file);
            }

            //NOW LETS GET THE GALLERY IMAGES APPENDED
            if(this.galleryData.length !== 0) {
                this.galleryData.forEach(function (data) {
                    formData.append('gallery_images[]', data.file);
                });
            }

            if (this.trustpilotUrl) {
                formData.append('trustpilot_url', this.trustpilotUrl);
            }


            //Send data to URl to update post along with the form data
            api.post("/products", formData, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .catch(
                    this.onFail.bind(this)
                )
                .then(
                    response => {
                        this.submitted = true;
                        this.isLoading = true;
                        this.errors = [];
                        location = response.data.message;
                    }
                );

        },
        /** Here we Look for errors and return to the catch block  **/
        onFail(error){
            this.errorsExist = true;
            flash('Opps, something went wrong', 'danger');
            this.errors = error.response.data.errors || [];
            this.submitted = false;
            this.isCreatingListing = false;
            this.isLoading = false;
            this.isDisabled = false;
            throw error;
        }
    },
}
</script>
