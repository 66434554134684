<template>
    <div class="dropdownAccount relative">

        <!-- Trigger -->
        <div class="dropdown-toggle"
             @click.prevent="isOpenAccount = !isOpenAccount"
             aria-haspopup="true"
             :aria-expanded="isOpenAccount">
            <slot name="trigger"></slot>
        </div>

        <!-- Notifications Links -->
        <div v-show="isOpenAccount"
             class=""
             :class="align === 'left' ? 'left-0' : 'right-0'"
             :style="{ width }">
            <slot></slot>
        </div>

    </div>
</template>

<script>

export default{
    props: {
        width: {default: 'auto'},
        align: { default: 'left-0'}
    },

    data() {
        return {isOpenAccount: false}
    },

    watch: {
        isOpenAccount(isOpenAccount){
            if (isOpenAccount){
                document.addEventListener('click', this.closeIfClickedOutside)
            }
        }
    },

    methods:{
        closeIfClickedOutside(event){
            if(! event.target.closest('.dropdownAccount')){
                this.isOpenAccount = false;
                document.removeEventListener('click', this.closeIfClickedOutside)
            }
        }

    }
}

</script>
