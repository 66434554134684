<template>
    <div id="trustpilot_accordion" v-if="blockActive">
        <div class="flex flex-col">
            <div class="relative w-full px-3 mb-6">
                <div class="horizontal flex flex-col my-4">
                    <label class="form_field_label" for="trustpilot_url">Trustpilot URL</label>
                    <input class="w-full form_field_input focus:outline-none" type="text" placeholder="Trustpilot URL" id="trustpilot_url" @change="updateTrustpilotUrl" v-model="setTrustpilotUrl">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
export default {
    data() {
        return {
            blockActive: true,
            setTrustpilotUrl: null
        }
    },
    props: {
        trustpilotUrl: {
            required: false,
            type: String,
            default: null
        }
    },
    mounted() {
        this.setTrustpilotUrl = this.trustpilotUrl;
    },
    methods: {
        updateTrustpilotUrl() {
            this.$emit('updateTrustpilotUrl', this.setTrustpilotUrl);
        }
    }
}
</script>
