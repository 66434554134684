<template>
    <div class="flex flex-row flex-wrap  my-4 w-full md:w-2/3 lg:w-3/4">
        <product-card-component v-for="(product, key) in products" :key="key" :product="product"></product-card-component>
    </div>
</template>

<script>
export default {
    props: {
        products: {
            required: false,
            type: Array,
            default: {}
        }
    }
}
</script>

<style scoped>

</style>
