<template>
	<article
		:class="{
			'shadow-lg rounded-lg overflow-hidden bg-white': true,
			'order-first': beingCompared
		}"
	>
		<a :href="`/products/${entry.slug}`" class="block xl:aspect-w-1 xl:aspect-h-2">
			<div>
				<div class="aspect-w-1 aspect-h-1">
					<div class="bg-gray-400">
						<img
							v-if="entry.product_image"
							:src="getGlideImage(entry.product_image_path, 322, 322)"
							:alt="entry.title || null"
							class="absolute top-0 left-0 w-full h-full object-cover"
							loading="lazy"
							width="322px"
							height="322px"
						>
						<button
							v-if="compare"
							:disabled="maxComparedReached && !beingCompared"
							:class="{
								'group absolute top-0 right-0 px-2 py-2': true,
								'pointer-events-none': maxComparedReached && !beingCompared
							}"
							type="button"
							@click.prevent="toggleCompare(entry)"
						>
							<div
								:class="{
									'flex items-center justify-center rounded-full shadow w-8 h-8 transition-all': true,
									'text-white bg-smarta-green-500 opacity-100': beingCompared,
									'text-smarta-green-500 group-hover:text-white bg-white group-hover:bg-smarta-green-500 opacity-100': !beingCompared && !maxComparedReached,
									'opacity-0': !beingCompared && maxComparedReached
								}"
							>
								<fa-icon :icon="['fas', 'right-left']" />
							</div>
						</button>
					</div>
				</div>
				<div class="xl:aspect-w-1 xl:aspect-h-1">
					<div class="flex flex-col text-gray-900 px-6 pt-4 pb-6">
						<h2 v-if="entry.title" :title="entry.title" :alt="entry.title" class="text-2xl line-clamp-2 mb-2">{{ entry.title }}</h2>
						<div class="flex-grow text-gray-500 mb-2 2xl:mb-4">
							<p
								v-if="entry.product_description"
								:class="{
									'line-clamp-2 xl:line-clamp-4 mb-0': true,
									'line-clamp-4': !entry.trustpilot || (entry.trustpilot && entry.trustpilot.rating === null)
								}"
							>
								{{ entry.product_description }}
							</p>
						</div>
						<div v-if="categories && categories.length > 0" class="categories flex mb-2">
							<div class="mr-2">
								<fa-icon :icon="['fas', 'star']" fixed-width />
							</div>
							<div class="flex-grow flex flex-wrap text-sm 2xl:text-base">
								<span
									v-for="(category, index) in categories"
									:key="index"
									class="mr-4"
								>{{ category.name }}</span>
							</div>
						</div>
						<div v-if="deals && deals.length > 0" class="deals flex mb-2">
							<div class="mr-2">
								<fa-icon :icon="['fas', 'tag']" fixed-width />
							</div>
							<div class="flex-grow flex flex-wrap text-sm 2xl:text-base">
								<span
									v-for="(deal, index) in deals"
									:key="index"
									class="mr-4"
								>{{ deal.name }}</span>
							</div>
						</div>
						<TrustPilot
							:entry="entry"
							:show-missing="false"
							class="mt-2"
						/>
					</div>
				</div>
			</div>
		</a>
	</article>
</template>
<script>
	import ShowsGlideImage from '../../mixins/ShowsGlideImage'
	import InteractsWithComparisonModule from '../../mixins/InteractsWithComparisonModule'
	import TrustPilot from './partials/TrustPilot'

	export default {
		name: 'ProductSingleDomino',

		components: {
			TrustPilot
		},

		mixins: [
			ShowsGlideImage,
			InteractsWithComparisonModule
		],

		props: {
			entry: {
				type: Object,
				required: true
			},

			compareFilter: {
				type: String,
				required: false,
				default: () => { return null }
			}
		},

		computed: {
			categories () {
				return this.entry.category || []
			},

			deals () {
				return this.entry.deal || []
			}
		}
	}
</script>
