<template>
    <div class="flex fixed bottom-0 md:top-[120px] md:bottom-unset w-full flex-col z-40">
        <div :class="open ? 'shop_sticky_card rounded-lg bg-white' : 'shop_sticky_closed'" class="w-full md:absolute md:w-3/12 right-0 mt-8 md:mt-20 md:mr-4 px-6 py-4 lg:p-4">
            <div class="flex justify-end mb-2">
                <button class="bg-smarta-blue-500 rounded-full focus:outline-none"  @click='toggle()'>
                    <svg v-if="open" class="w-8 h-8 text-white focus:outline-none"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path></svg>
                    <svg v-else  class="w-8 h-8 text-white focus:outline-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                </button>
            </div>
            <div v-if="open" class="flex justify-between items-start flex-col">
                <div class="flex justify-between items-start w-full">
                    <h3 class="text-smarta-green-500 mt-0 mx-4 mb-2"><a :href="buyHref" class="hover:text-smarta-green-700">{{ product.title }}</a></h3>
                    <img class="sticky_logo max-w-sm rounded-lg" :src="imageSrc">
                </div>
                <a class="mt-4" :href="product.trustpilot_url" target="_blank" v-if="star_image && review_count !== null">
                    <div v-if="product.trustpilot_url" class="mx-4 flex items-start" v-html="trustpilotStarImage(star_image)"></div>
                    <p class="mx-4" v-if="product.trustpilot_url"><small>TrustScore <b>{{ score }}</b> | {{ review_count }} reviews</small></p>
                </a>
                <div class="flex flex-col mx-4">
                    <div class="flex items-start">
                        <div class="flex">
                            <svg class="w-6 h-6 text-smarta-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                        </div>
                        <div class="flex flex-wrap" >
                            <span class="ml-2 text-smarta-green-500 " v-for="(category, key) in product.category" :key="key" v-text="category.name"></span>
                        </div>
                    </div>
                    <div class="flex my-4 items-start">
                        <div class="flex">
                            <svg class="w-6 h-6 text-smarta-pink-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                        </div>
                        <div class="flex flex-wrap" >
                            <span class="ml-2 text-smarta-pink-500 "  v-for="(deal, key) in product.deal" :key="key" v-text="deal.name"></span>
                        </div>
                    </div>
                </div>
                <slot />
            </div>
            <div v-else class="flex justify-between items-end flex-col">
                <div class="flex justify-between">
                    <button
                        type="button"
                        class="rounded-lg px-8 py-4 bg-smarta-green-500 cursor-pointer"
                        @click.prevent="toggle"
                    >
                        <h4 class="text-white text-center uppercase text-xl whitespace-nowrap">TRY NOW</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true
        },

        score: {
            type: [String, Number],
            required: false,
            default: () => { return false }
        },

        star_image: {
            type: String,
            required: false,
            default: () => { return null }
        },

        review_count: {
            type: [String, Number],
            required: false,
            default: () => { return null }
        }
    },

    data () {
        return {
            open: true,
            readMoreActivated: false,
            screenWidth: window.innerWidth
        };
    },

    computed: {
        imageSrc () {
            return this.product.product_image_path || '/images/holding_image.svg';
        },

        buyHref () {
            return `/shop/${this.product.slug}`
        }
    },

    mounted () {
        if (this.screenWidth < 700) {
            this.open = false;
        }
        this.readMoreActivated = (this.product.product_description.split(' ').length > 14)
    },

    methods: {
        toggle () {
            this.open = !this.open
        },

        activateReadMore () {
            this.readMoreActivated = true;
        },

        trustpilotStarImage (image) {
            return image !== null ? `<img style="max-height:30px" src="${image}">` : null
        }
    }
}
</script>
