const state = {
    show: false,
    embed: null,
    title: null
}

const getters = {
    visible: (state) => {
        return state.show
    },

    embed: (state) => {
        return state.embed
    },

    title: (state) => {
        return state.title
    }
}

const mutations = {
    open (state, url) {
        state.embed = url
        setTimeout(() => {
            state.show = true
        }, 100)
    },

    close (state) {
        state.show = false
        setTimeout(() => {
            state.embed = null
        }, 500)
    },

    setTitle (state, title) {
        state.title = title
    },

    clearTitle (state) {
        state.title = null
    }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
