<template>
    <div class="grid bg-gray-200 grid-cols-5 py-16 px-16">
        <img src="/images/profile_image.svg">
        <div class="col-span-4 ml-4 flex items-center"> 
            <div>
                <h3>{{ name }}</h3>
                <h4>{{ position }}</h4>
            </div>
        </div>
        <h2 class="pt-8 pb-4 col-span-5">{{ title }}</h2>
        <div class="flex col-span-5">
            <div class="w-2/6"><img src="/images/quotes_open.svg"></div>
            <p class="mt-2 ml-2">Tart caramels cheesecake dragée candy jelly-o gummies. Toffee sweet dessert marzipan wafer. Sesame snaps chocolate bar cake soufflé candy gummies danish bear claw. Brownie biscuit gingerbread lollipop macaroon lemon drops.</p>
            <div class="relative w-2/6"><img class="absolute" style="bottom:0;" src="/images/quotes_close.svg"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            required: false,
            type: String,
            default: "Name Surname"
        },
        position: {
            required: false,
            type: String,
            default: "Position"
        },
        title: {
            required: false,
            type: String,
            default: "Testimonial title goes here"
        }
    }
}
</script>

<style scoped>
h3 {
    color: #84be55;
}

h4 {
    color: #df0170;
}
</style>
