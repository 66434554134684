<template>
    <div
        :aria-hidden="!isOpen"
        :class="{
            'flash snack px-3 py-3 font-display font-bold shadow-lg border-0 mb-4 flex items-center is-success': true,
            'is-visible': isOpen,
            ...levelClass
        }"
    >
        <div v-if="levelClass === 'is-danger'">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </div>
        <div v-else>
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </div>
        <span class="tracking-wide ml-2">{{ messageText }}</span>
        <button class="delete focus:outline-none" @click="isOpen=false">
            <svg class="h-5 w-5 text-gray-500 stroke-current ml-2 focus:outline-none" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"><path d="M6 18L18 6M6 6l12 12"></path></svg>
        </button>
    </div>
</template>

<script>
export default {
    props: ['level', 'message'],
    data() {
        return {
            isOpen: false,
            closeAfter: 3000,// 3 seconds, you can change that
            levelClass: null,
            messageText: null
        }
    },
    created() {
        if (this.level) {
            this.levelClass = 'is-' + this.level;
        }
        if (this.message) {
            this.messageText = this.message;
            this.flash();
        }
        let self = this;
        window.events.$on(
            'flash', data => self.flash(data)
        );
    },
    methods: {
        flash(data) {
            if (data) {
                this.messageText = data.message;
                this.levelClass = 'is-' + data.level;
            }

            let self = this;

            setTimeout(() => {
                self.isOpen = true;
            }, 100);

            this.hide();
        },
        hide() {
            let self = this;

            setTimeout(() => {
                self.isOpen = false;
            }, self.closeAfter);
        }
    },
}
</script>

<style >
.flash.snack {
    z-index: 9999;
    position: fixed;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    transform: translate(100%);
    transition: all 0.6s ease-in-out;

}
.flash.snack.is-visible{
    transform: translate(0);
    opacity: 1;
}

</style>
