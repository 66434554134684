<template>
    <input v-model="query" @change="updateSearch" type="text" placeholder="Search..." class="w-full md:w-1/2  form_field_input focus:outline-none">
</template>

<script>
export default {
    data() {
        return {
            query: ""
        }
    },
    props: {
        previousQuery: {
            required: false,
            type: String,
            default: null
        }
    },
    methods: {
        updateSearch() {
            this.$root.$emit('updateSearch', this.query);
        }
    },
    mounted() {
        if (this.previousQuery != null) {
            this.query = this.previousQuery;
        }
    }
}
</script>

<style scoped>
::placeholder {
    color: #df0170;
}
</style>
