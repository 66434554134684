<template>
    <div class="w-full flex flex-col md:w-1/2 lg:w-1/3 my-4">
        <div class="card product drop-shadow flex-grow mx-3">
            <a :href="buyHref">
                <img class="object-fill mx-auto" :src="imageSrc">
            </a>
            <h3 class="mx-4 text-smarta-green-500 mt-4 mb-2"><a :href="buyHref" class="hover:text-smarta-green-700">{{ product.title }}</a></h3>

            <p class="px-4 ">
                {{ product.product_description.split(" ").slice(0, 14).join(" ") }}
                <a class="text-smarta-pink-500 hover:text-smarta-pink-700" :href="buyHref" :key="readMoreActivated" >
                    read more...
                </a>
            </p>

            <a class="flex-grow" :href="product.trustpilot_url" target="_blank" v-if="product.trustpilot_rating != null">
                <div class="mx-4 flex items-start" v-html="trustpilotStarImage(product)"></div>
                <p class="mx-4" v-if="product.trustpilot_rating != null"><small>TrustScore <b>{{ product.trustpilot_rating.score }}</b> | {{ product.trustpilot_rating.review_count }} reviews</small></p>
            </a>

            <div class="flex flex-col mx-4">
                <div class="flex items-start">
                    <div class="flex">
                        <svg class="w-6 h-6 text-smarta-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                    </div>
                    <div class="flex flex-wrap" >
                        <span class="ml-2 text-smarta-green-500 " v-for="(category, key) in product.category" :key="key" v-text="category.name"></span>
                    </div>
                </div>
                <div class="flex my-4 items-start">
                    <div class="flex">
                        <svg class="w-6 h-6 text-smarta-pink-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                    </div>
                    <div class="flex flex-wrap" >
                        <span class="ml-2 text-smarta-pink-500 "  v-for="(deal, key) in product.deal" :key="key" v-text="deal.name"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            reqiured: true,
            type: Object
        }
    },
    data() {
        return {
            readMoreActivated: false
        };
    },
    computed: {
        imageSrc() {
            return this.product.product_image_path || "/images/holding_image.svg";
        },
        buyHref() {
            return "/shop/" + this.product.slug;
        }
    },
    mounted() {

        if(this.product.product_description.split(' ').length > 14){
            this.readMoreActivated = true;
        }else{
            this.readMoreActivated = false;
        }

    },
    methods: {
        activateReadMore(){
            this.readMoreActivated = true;
        },
        trustpilotStarImage(product) {
            if (product.trustpilot_rating == null || product.trustpilot_rating.star_image == null) {
                return null;
            }

            return '<img style="max-height:30px" src="' + product.trustpilot_rating.star_image + '">';
        }
    }
}
</script>

<style scoped>
.card {
    background: white;
}

h3 {
    color: #84be55;
}
</style>
