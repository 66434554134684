import { find, map, cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

export default {
	props: {
		compare: {
			type: Boolean,
			required: false,
			default: () => { return true }
		}
	},
	
	data () {
		return {
			max: 3
		}
	},

	computed: {
		beingCompared () {
			return typeof find(this.items, { id: this.entry.id }) !== 'undefined'
		},
		maxComparedReached () {
			return this.items.length === this.max
		},
		...mapGetters('compare', [
			'items',
			'count'
		])
	},

	methods: {
		toggleCompare (item) {
			if (this.beingCompared) {
				this.$store.commit('compare/remove', item.id)
			} else {
				this.$store.commit('compare/add', cloneDeep(item))

				if (this.compareFilter && typeof item[this.compareFilter] !== 'undefined' && item[this.compareFilter].length > 0 && this.count <= 1) {
					this.$emit('add-filter', this.compareFilter, map(item[this.compareFilter], (filter) => {
						return filter.slug
					}))
				}
			}
		}
	}
}
