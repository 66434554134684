<template>
    <div class="mx-2">
        <div class="mb-4">
            <h4>{{ count === length ? 'Profile complete!' : 'Complete your profile' }}</h4>
        </div>
        <div class="flex flex-row items-center">
            <span class="relative rounded-full border-2 border-smarta-blue-500 bg-white -mr-2 z-[2]">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
            </span>
            <ul class="relative progressbar space-x-1 z-[1]">
                <li
                    v-for="index in length"
                    :key="index"
                    :class="{
                        'bg-gray-300': index > count,
                        'bg-smarta-green-500': index <= count && count !== length,
                        'bg-smarta-pink-500': count === length
                    }"
                />
            </ul>
            <span class="relative rounded-full text-smarta-pink-500 border-2 border-smarta-pink-500 bg-white -ml-2  z-[2]">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            </span>
        </div>
    </div>
</template>
<script>
import { filter } from 'lodash'

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            fields: {
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                job_company: this.user.user_company,
                job_company_address: this.user.user_company_address,
                job_company_postcode: this.user.user_company_postcode,
                job_title: this.user.user_profession,
                telephone: this.user.user_telephone,
                address: this.user.user_address,
                postcode: this.user.user_postcode,
            },
            activeItem: null
        };
    },

    computed: {
        length () {
            return Object.values(this.fields).length
        },

        count () {
            return filter(Object.values(this.fields), (value) => {
                return typeof value !== 'undefined' && value !== null && value !== ''
            }).length
        }
    }
}
</script>
<style scoped>
    .progressbar {
        display: flex;
        width: 100%;
    }
    .progressbar li {
        width: 100%;
        height: 12px;
    }
</style>
